// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transcript-subtitle {
  position: fixed;
  bottom: 10vh;
  width: 100vw;
  min-height: 50px;
  background: rgba(255, 255, 255, 0.2);
  display: none;
  justify-content: center;
  padding: 12px 0;
}
.transcript-subtitle.transcript-subtitle-show {
  display: flex;
}
.transcript-subtitle-message {
  color: #fff;
  font-size: 16px;
  display: flex;
  width: 50vw;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/feature/video/components/transcription-subtitle.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;EACA,oCAAA;EACA,aAAA;EACA,uBAAA;EACA,eAAA;AACF;AAAE;EACE,aAAA;AAEJ;AAAE;EACE,WAAA;EACA,eAAA;EACA,aAAA;EACA,WAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AAEJ","sourcesContent":[".transcript-subtitle{\n  position: fixed;\n  bottom: 10vh;\n  width: 100vw;\n  min-height: 50px;\n  background: rgba(255,255,255,0.2);\n  display: none;\n  justify-content: center;\n  padding: 12px 0;\n  &.transcript-subtitle-show{\n    display: flex;\n  }\n  &-message {\n    color: #fff;\n    font-size: 16px;\n    display: flex;\n    width: 50vw;\n    justify-content: center;\n    align-items: center;\n    flex-wrap: wrap;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
