import React from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import { IconFont } from '../../../component/icon-font';

interface ChatButtonProps {
  onParticipantsClick: () => void;
}

const ParticipantsButton = (props: ChatButtonProps) => {
  const { onParticipantsClick } = props;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment

    <Button
      className={classNames('screen-share-button', 'vc-button')}
      icon={<IconFont type="icon-participants" />}
      ghost={true}
      shape="circle"
      size="large"
      onClick={onParticipantsClick}
    />
  );
};

export { ParticipantsButton };
