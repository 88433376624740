// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.join-by-phone-dialog .ant-form-item {
  margin-bottom: 10px;
}
.join-by-phone-dialog .phone-number {
  display: flex;
}
.join-by-phone-dialog .phone-number .country-code {
  flex-basis: 300px;
}
.join-by-phone-dialog .phone-number .number {
  flex-grow: 1;
}
.join-by-phone-dialog .phone-call-status .status-text {
  margin-left: 15px;
}
.join-by-phone-dialog .phone-call-status .pending {
  color: #666;
}
.join-by-phone-dialog .phone-call-status .fail {
  color: #f00;
}
.join-by-phone-dialog .phone-call-status .success {
  color: green;
}`, "",{"version":3,"sources":["webpack://./src/feature/video/components/call-out-modal.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;AAAJ;AAEE;EACE,aAAA;AAAJ;AACI;EACE,iBAAA;AACN;AACI;EACE,YAAA;AACN;AAGI;EACE,iBAAA;AADN;AAGI;EACE,WAAA;AADN;AAGI;EACE,WAAA;AADN;AAGI;EACE,YAAA;AADN","sourcesContent":[".join-by-phone-dialog {\n  .ant-form-item {\n    margin-bottom: 10px;\n  }\n  .phone-number {\n    display: flex;\n    .country-code {\n      flex-basis: 300px;\n    }\n    .number {\n      flex-grow: 1;\n    }\n  }\n  .phone-call-status {\n    .status-text{\n      margin-left: 15px;\n    }\n    .pending{\n      color: #666;\n    }\n    .fail {\n      color: #f00;\n    }\n    .success {\n      color: green;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
