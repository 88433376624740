import React, { useCallback, useContext, useEffect, useState, useRef, useLayoutEffect, MutableRefObject } from 'react';
import { Input, message, Modal } from 'antd';
import ZoomContext from '../../../context/zoom-context';
import { useGalleryLayout } from '../hooks/useGalleryLayout';
import { usePagination } from '../hooks/usePagination';
import { ZoomClient, MediaStream, Participant } from '../../../index-types';
import { Dimension, Pagination } from '../video-types';
import { Avatar, Button } from 'antd';
import { IconFont } from '../../../component/icon-font';
import { LoadingOutlined } from '@ant-design/icons';

import './sms.scss';
import axios from 'axios';
import { getMeetingFromDb } from '../../../utils/api';
import { BASE_URL_SERVER } from '../../../config/dev';

interface SmsProps {
  zmClient: ZoomClient;
  mediaStream: MediaStream | null;
  isVideoDecodeReady: boolean;
  videoRef: MutableRefObject<HTMLCanvasElement | null>;
  canvasDimension: Dimension;
  showSmsModal?: boolean;
  setShowSmsModal?: (showSmsModal: boolean) => void;
}

const Sms = (props: SmsProps) => {
  const { zmClient, mediaStream, isVideoDecodeReady, videoRef, canvasDimension, showSmsModal, setShowSmsModal } = props;
  const { page, pageSize, totalPage, totalSize, setPage } = usePagination(zmClient, canvasDimension);
  //   const zmClient = useContext(ZoomContext);
  const { visibleParticipants, layout: videoLayout } = useGalleryLayout(
    zmClient,
    mediaStream,
    isVideoDecodeReady,
    videoRef,
    canvasDimension,
    {
      page,
      pageSize,
      totalPage,
      totalSize
    }
  );
  const [smsNumber, setSmsNumber] = useState<string>('');
  const [SMSLoading, setSMSLoading] = useState<boolean>(false);

  //   const { TextArea } = Input;
  // useLayoutEffect(() => {}, []);

  const onMuteUnmuteAudio = useCallback((participant: Participant) => {
    if (participant?.audio) {
      if (participant?.muted) {
        mediaStream?.unmuteAudio(participant?.userId);
      } else {
        mediaStream?.muteAudio(participant?.userId);
      }
    }
  }, []);

  const onOpenCloseVideo = useCallback((participant: Participant) => {
    if (participant?.isVideoConnect) {
      if (participant?.bVideoOn) {
        mediaStream?.detachVideo(participant?.userId);
      } else {
        // mediaStream?.attachVideo(participant?.userId);
      }
    }
  }, []);
  // eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTS2MwZjMwOTkzMThjNjAxYjI2NTgwZTMwNGY3ZjcwMjU5LTE3MTE2MTIyMjEiLCJncmFudHMiOnsiaWRlbnRpdHkiOiJNb2VlbiBUYXJpcSIsInZpZGVvIjp7InJvb20iOiIwMDU0eDAwMDAwNWhyWllhMXk3ZTAwMDAwMmNGRjFBQU0ifSwiY2hhdCI6eyJzZXJ2aWNlX3NpZCI6IklTMzg0ODUyN2FlM2JhNGJkMWFmMzM1NDkwZGM2MTAyNjYifX0sImlhdCI6MTcxMTYxMjIyMSwiZXhwIjoxNzExNjI2NjIxLCJpc3MiOiJTS2MwZjMwOTkzMThjNjAxYjI2NTgwZTMwNGY3ZjcwMjU5Iiwic3ViIjoiQUNmMWE5ZDQ4NzljN2EyOGFlM2YyNGEzN2M3NTMwMzg4MSJ9.2oq0x5Eqgmh4fuczAsfcNKgMT6tZCh5fCbC4WbZgbFo
  //   {
  //     "number": "+923162150074",
  //     "origin": "https://videocalling.jobsitecare.com",
  //     "link": "cKR5uYauDYz4l9TD/x88ujI29B8BVzGon4wSvvaOlq7+8JWBwH4PgeWPyYq/FpS1ecsTWMxeX5bCdCtuo79FGeRA2lqHLGPllMFr9VcI2Ierdm9s3fHNIZKw30t1x0jrUhQ+O/52Rx5f301RBT/jX5ww14t2ks3hfJjHM8moaCUlBPmF7ZPXmL1bUWF6CmLiaYA870Oh++MQPcGgluw3cYUXqI9pdVwFGuUHeSBU9Bs=ZZZZ",
  //     "participantname": "+923162150074",
  //     "roomname": "0054x000005hrZYa1y7e000002cFF1AAM",
  //     "username": "Moeen Tariq"
  // }
  const sendSMS = useCallback(async () => {
    setSMSLoading(true);
    console.log('sendSMS number', smsNumber);
    // const response = await axios.post('https://jobsitecare.my.salesforce.com/services/oauth2/token', data, {
    //   headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //   },
    // });
    // await axios.get('https://videocalling.jobsitecare.com/salesforce/token').then(async (response) => {
    //   console.log(response, 'tokena');
    //   // const config = {
    //   //   headers: {
    //   //     Authorization: `Bearer ${response.data}`,
    //   //   },
    //   // };
    // });
    if (smsNumber.length == 0) {
      // alert('please enter valid number');
      message.warn('Please enter valid number');
    } else {
      const urlParams = new URLSearchParams(location?.search);
      const recordIdParam: string = urlParams.get('recordId') || '';
      const SFRecordIdLocal: any = localStorage.getItem('SFRecordId');
      const SFRecordId = recordIdParam ? recordIdParam : SFRecordIdLocal;
      const getMeetingFromDbresponse = await getMeetingFromDb(SFRecordId);
      console.log('getMeetingFromDbresponse', getMeetingFromDbresponse);
      const data = {
        name: smsNumber.toString(),
        meetingId: getMeetingFromDbresponse.data[0].meeting_id
      };
      console.log('data', data);
      // const encryptionKeyBase64 = 'T543oUJD9RzlE7YzlGesLpqCL988CV1Zavj7hvjpTQw='; // Your encryption key
      // const encryptedInfo = await encryptWithManagedIV(data, encryptionKeyBase64);
      const encryptedInfo = `name=${data?.name}&number=${data?.name}&meetingId=${data?.meetingId}`;
      // const encryptedInfo = `name=${data?.name}&meetingId=${data?.meetingId}`;

      console.log('encryptedInfo', encryptedInfo);
      try {
        const response = await axios.post(`${BASE_URL_SERVER}/sms`, {
          number: data?.name,
          origin: window.location.origin + '/join',
          participantname: data?.name,
          roomname: 'zoom-demo',
          // link: 'cKR5uYauDYz4l9TD/x88ujI29B8BVzGon4wSvvaOlq7+8JWBwH4PgeWPyYq/FpS1ecsTWMxeX5bCdCtuo79FGeRA2lqHLGPllMFr9VcI2Ierdm9s3fHNIZKw30t1x0jrUhQ+O/52Rx5f301RBT/jX5ww14t2ks3hfJjHM8moaCUlBPmF7ZPXmL1bUWF6CmLiaYA870Oh++MQPcGgluw3cYUXqI9pdVwFGuUHeSBU9Bs=ZZZZ',
          link: encryptedInfo,
          username: zmClient.getCurrentUserInfo().displayName
        });
        console.log('sendSMS', response);
        if (response?.data?.success) {
          if (zmClient.getCurrentUserInfo().isHost) {
            setSMSLoading(false);
            setShowSmsModal?.(false);
            message.success('SMS Invite has been sent');
            setSmsNumber('');
          }
          // alert('SMS has been sent successfully');
        }
      } catch (error) {
        setSMSLoading(false);
        setShowSmsModal?.(false);
        message.error('Something went wrong with SMS Invite!');
        console.log('SMS error', error);
      }
      // const btn = document.getElementById('closebutton')?.click();
    }
  }, [smsNumber]);

  const handleNumberClick = useCallback(
    (number: string) => {
      setSmsNumber((num) => num + number);
    },
    [smsNumber]
  );

  return (
    <Modal
      open={showSmsModal}
      title="SMS"
      footer={null}
      closable
      centered
      onCancel={() => setShowSmsModal?.(false)}
      width={'35%'}
    >
      {/* <div className="participants-wrap"> */}
      <div className="dialer-pad">
        <div className="row">
          <Input
            type="text"
            value={smsNumber}
            style={{ padding: '18px' }}
            size="large"
            onChange={(e) => {
              console.log('e.target.value', e.target.value);
              setSmsNumber(e.target.value);
            }}
          />
        </div>
        <div className="row">
          <button onClick={() => handleNumberClick('1')}>1</button>
          <button onClick={() => handleNumberClick('2')}>2</button>
          <button onClick={() => handleNumberClick('3')}>3</button>
        </div>
        <div className="row">
          <button onClick={() => handleNumberClick('4')}>4</button>
          <button onClick={() => handleNumberClick('5')}>5</button>
          <button onClick={() => handleNumberClick('6')}>6</button>
        </div>
        <div className="row">
          <button onClick={() => handleNumberClick('7')}>7</button>
          <button onClick={() => handleNumberClick('8')}>8</button>
          <button onClick={() => handleNumberClick('9')}>9</button>
        </div>
        <div className="row">
          <button onClick={() => handleNumberClick('-')}>-</button>
          <button onClick={() => handleNumberClick('0')}>0</button>
          <button onClick={() => handleNumberClick('+')}>+</button>
        </div>
        <div className="row">
          <button disabled={SMSLoading} className="button-send" onClick={() => sendSMS()}>
            {SMSLoading ? <LoadingOutlined style={{ fontSize: '25px', color: '#fff' }} /> : ' Send invite'}
          </button>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
};

export default Sms;
