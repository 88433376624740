import { getExploreName } from '../utils/platform';

export const devConfig = {
  sdkKey: 'c8aTXAcxSCmtX5hhuFzRoQ',
  sdkSecret: 'qhmCCpiAaJBcJr7wkfSe6ZdgSNFkEoKE7tNu',
  webEndpoint: 'zoom.us',
  topic: 'Cool Car',
  name: `${getExploreName()}-${Math.floor(Math.random() * 1000)}`,
  password: 'abc1234',
  signature: '',
  sessionKey: '',
  userIdentity: '',
  // The user role. 1 to specify host or co-host. 0 to specify participant, Participants can join before the host. The session is started when the first user joins. Be sure to use a number type.
  role: 1
};

export const BASE_URL_LOCAL = 'http://localhost:8081';
export const BASE_URL_SERVER = 'https://avserver.jobsitecare.com';
