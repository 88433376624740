import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import './participant-removed.scss';

const UserLeft = () => {
  return (
    <div className="viewport">
      <div style={{ margin: 'auto' }}>
        <h1>You have Left the meeting.</h1>
      </div>
    </div>
  );
};

export default UserLeft;
