import React, { useCallback, useContext, useEffect, useState, useRef, useLayoutEffect, MutableRefObject } from 'react';
import { Input, message, Modal } from 'antd';
import ZoomContext from '../../../context/zoom-context';
import { useGalleryLayout } from '../hooks/useGalleryLayout';
import { usePagination } from '../hooks/usePagination';
import { ZoomClient, MediaStream, Participant } from '../../../index-types';
import { Dimension, Pagination } from '../video-types';
import { Avatar, Button } from 'antd';
import { IconFont } from '../../../component/icon-font';
import { LoadingOutlined } from '@ant-design/icons';

import './sms.scss';
import axios from 'axios';
import { getMeetingFromDb, getPatientInfoByRecordId, getUserFromDb } from '../../../utils/api';
import { BASE_URL_SERVER } from '../../../config/dev';

interface PatientCallSmsProps {
  zmClient: ZoomClient;
  mediaStream: MediaStream | null;
  isVideoDecodeReady: boolean;
  videoRef: MutableRefObject<HTMLCanvasElement | null>;
  canvasDimension: Dimension;
  showPatientCallSmsModal?: boolean;
  setShowPatientCallSmsModal?: (showPatientCallSmsModal: boolean) => void;
  onPhoneCallClick?: (code: string, phoneNumber: string, name: string, option: any) => void;
  phoneCallStatus?: { text: string; type: string };
}

const PatientCallSms = (props: PatientCallSmsProps) => {
  const {
    zmClient,
    mediaStream,
    isVideoDecodeReady,
    videoRef,
    canvasDimension,
    showPatientCallSmsModal,
    setShowPatientCallSmsModal,
    onPhoneCallClick,
    phoneCallStatus
  } = props;
  const { page, pageSize, totalPage, totalSize, setPage } = usePagination(zmClient, canvasDimension);
  //   const zmClient = useContext(ZoomContext);
  const { visibleParticipants, layout: videoLayout } = useGalleryLayout(
    zmClient,
    mediaStream,
    isVideoDecodeReady,
    videoRef,
    canvasDimension,
    {
      page,
      pageSize,
      totalPage,
      totalSize
    }
  );
  const [smsNumber, setSmsNumber] = useState<string>('');
  const [SMSLoading, setSMSLoading] = useState<boolean>(false);

  //   const { TextArea } = Input;
  // useLayoutEffect(() => {}, []);

  const onMuteUnmuteAudio = useCallback((participant: Participant) => {
    if (participant?.audio) {
      if (participant?.muted) {
        mediaStream?.unmuteAudio(participant?.userId);
      } else {
        mediaStream?.muteAudio(participant?.userId);
      }
    }
  }, []);

  const onOpenCloseVideo = useCallback((participant: Participant) => {
    if (participant?.isVideoConnect) {
      if (participant?.bVideoOn) {
        mediaStream?.detachVideo(participant?.userId);
      } else {
        // mediaStream?.attachVideo(participant?.userId);
      }
    }
  }, []);
  // eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTS2MwZjMwOTkzMThjNjAxYjI2NTgwZTMwNGY3ZjcwMjU5LTE3MTE2MTIyMjEiLCJncmFudHMiOnsiaWRlbnRpdHkiOiJNb2VlbiBUYXJpcSIsInZpZGVvIjp7InJvb20iOiIwMDU0eDAwMDAwNWhyWllhMXk3ZTAwMDAwMmNGRjFBQU0ifSwiY2hhdCI6eyJzZXJ2aWNlX3NpZCI6IklTMzg0ODUyN2FlM2JhNGJkMWFmMzM1NDkwZGM2MTAyNjYifX0sImlhdCI6MTcxMTYxMjIyMSwiZXhwIjoxNzExNjI2NjIxLCJpc3MiOiJTS2MwZjMwOTkzMThjNjAxYjI2NTgwZTMwNGY3ZjcwMjU5Iiwic3ViIjoiQUNmMWE5ZDQ4NzljN2EyOGFlM2YyNGEzN2M3NTMwMzg4MSJ9.2oq0x5Eqgmh4fuczAsfcNKgMT6tZCh5fCbC4WbZgbFo
  //   {
  //     "number": "+923162150074",
  //     "origin": "https://videocalling.jobsitecare.com",
  //     "link": "cKR5uYauDYz4l9TD/x88ujI29B8BVzGon4wSvvaOlq7+8JWBwH4PgeWPyYq/FpS1ecsTWMxeX5bCdCtuo79FGeRA2lqHLGPllMFr9VcI2Ierdm9s3fHNIZKw30t1x0jrUhQ+O/52Rx5f301RBT/jX5ww14t2ks3hfJjHM8moaCUlBPmF7ZPXmL1bUWF6CmLiaYA870Oh++MQPcGgluw3cYUXqI9pdVwFGuUHeSBU9Bs=ZZZZ",
  //     "participantname": "+923162150074",
  //     "roomname": "0054x000005hrZYa1y7e000002cFF1AAM",
  //     "username": "Moeen Tariq"
  // }
  const sendSMS = useCallback(async () => {
    setSMSLoading(true);
    // console.log('sendSMS number', patientNumber);
    // if (patientNumber.length == 0) {
    //   message.warn('Please enter valid number');
    // } else {
    const SFUserIdLocal: any = localStorage.getItem('SFUserId');
    const urlParams = new URLSearchParams(location?.search);
    const recordIdParam: string = urlParams.get('recordId') || '';
    const SFRecordIdLocal: any = localStorage.getItem('SFRecordId');
    const SFRecordId = recordIdParam ? recordIdParam : SFRecordIdLocal;
    const getMeetingFromDbResponse = await getMeetingFromDb(SFRecordId);
    const getUserFromDbResponse = await getUserFromDb(SFUserIdLocal);
    const getPatientInfoByRecordIdResponse = await getPatientInfoByRecordId(
      SFRecordId,
      getUserFromDbResponse?.data[0]?.access_token
    );
    console.log('getPatientInfoByRecordIdResponse', getPatientInfoByRecordIdResponse);
    console.log('getMeetingFromDbresponse', getMeetingFromDbResponse);
    const data = {
      name: getMeetingFromDbResponse.data[0].patient_name,
      number: getMeetingFromDbResponse.data[0].patient_contact,
      meetingId: getMeetingFromDbResponse.data[0].meeting_id
    };
    console.log('data', data);
    const encryptedInfo = `name=${encodeURIComponent(data?.name)}&number=${data?.number}&meetingId=${data?.meetingId}`;

    // const encryptedInfo = `name=${data?.number}&meetingId=${data?.meetingId}`;

    console.log('encryptedInfo', encryptedInfo);
    try {
      const response = await axios.post(`${BASE_URL_SERVER}/sms`, {
        number: data?.number,
        origin: window.location.origin + '/join',
        participantname: data?.name,
        roomname: 'zoom-demo',
        link: encryptedInfo,
        username: zmClient.getCurrentUserInfo().displayName
      });
      console.log('sendSMS', response);
      if (response?.data?.success) {
        if (zmClient.getCurrentUserInfo().isHost) {
          setSMSLoading(false);
          setShowPatientCallSmsModal?.(false);
          message.success('SMS Invite has been sent');
        }
      }
    } catch (error) {
      setSMSLoading(false);
      setShowPatientCallSmsModal?.(false);
      message.error('Something went wrong with SMS Invite!');
      console.log('SMS error', error);
    }
    // }
  }, []);

  const callPatient = useCallback(async () => {
    const SFUserIdLocal: any = localStorage.getItem('SFUserId');
    const urlParams = new URLSearchParams(location?.search);
    const recordIdParam: string = urlParams.get('recordId') || '';
    const SFRecordIdLocal: any = localStorage.getItem('SFRecordId');
    const SFRecordId = recordIdParam ? recordIdParam : SFRecordIdLocal;
    const getMeetingFromDbResponse = await getMeetingFromDb(SFRecordId);
    const getUserFromDbResponse = await getUserFromDb(SFUserIdLocal);
    const getPatientInfoByRecordIdResponse = await getPatientInfoByRecordId(
      SFRecordId,
      getUserFromDbResponse?.data[0]?.access_token
    );
    onPhoneCallClick?.(
      '+92',
      getPatientInfoByRecordIdResponse?.data?.phoneNumber,
      getPatientInfoByRecordIdResponse?.data?.phoneNumber,
      null
    );
  }, []);

  console.log('phoneCallStatus', phoneCallStatus);

  return (
    <Modal
      open={showPatientCallSmsModal}
      title="Invite Pateint"
      footer={null}
      closable
      centered
      onCancel={() => setShowPatientCallSmsModal?.(false)}
      width={'35%'}
    >
      {/* <div className="participants-wrap"> */}
      <div className="dialer-pad">
        <div className="row">
          <button disabled={SMSLoading} className="button-send" onClick={() => sendSMS()}>
            {SMSLoading ? <LoadingOutlined style={{ fontSize: '25px', color: '#fff' }} /> : ' Send SMS'}
          </button>
        </div>
        <div className="row">
          <button disabled={phoneCallStatus ? true : false} className="button-send" onClick={() => callPatient()}>
            {phoneCallStatus ? phoneCallStatus.text : 'Call'}
          </button>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
};

export default PatientCallSms;
