import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import ZoomContext from '../../context/zoom-context';
import ZoomMediaContext from '../../context/media-context';
import AvatarActionContext from './context/avatar-context';
import Avatar from './components/avatar';
import VideoFooter from './components/video-footer';
import Pagination from './components/pagination';
import { useCanvasDimension } from './hooks/useCanvasDimension';
import { useGalleryLayout } from './hooks/useGalleryLayout';
import { usePagination } from './hooks/usePagination';
import { useActiveVideo } from './hooks/useAvtiveVideo';
import { useAvatarAction } from './hooks/useAvatarAction';
import { useNetworkQuality } from './hooks/useNetworkQuality';
import ReportBtn from './components/report-btn';
import ShareView from './components/share-view';
import RemoteCameraControlPanel from './components/remote-camera-control';
import { getPhoneCallStatusDescription, SELF_VIDEO_ID } from './video-constants';
import Chat from '../chat/chat';
import ChatModal from '../chat/chat-modal';
import { Modal, Menu, message } from 'antd';

import './video.scss';
import Participants from './components/participants';
import Sms from './components/sms';
import { DialOutOption, DialoutState } from '@zoom/videosdk';
import { useMount } from '../../hooks';
import CallOutModal from './components/call-out-modal';
import PatientCallSms from './components/patient-call-sms';
const VideoContainer = (props: any) => {
  const zmClient = useContext(ZoomContext);
  const {
    mediaStream,
    video: { decode: isVideoDecodeReady }
  } = useContext(ZoomMediaContext);
  console.log('props');
  const videoRef = useRef<HTMLCanvasElement | null>(null);
  const shareViewRef = useRef<{ selfShareRef: HTMLCanvasElement | HTMLVideoElement | null }>(null);
  const [isRecieveSharing, setIsRecieveSharing] = useState(false);
  const [isChat, setIsChat] = useState(false);
  const [showParticipants, setShowParticipants] = useState(false);
  const [showSmsModal, setShowSmsModal] = useState(false);
  const [showPatientCallSmsModal, setShowPatientCallSmsModal] = useState(false);
  const [showPhoneCallModal, setShowPhoneCallModal] = useState(false);
  const [phoneCountryList, setPhoneCountryList] = useState<any[]>([]);
  const [phoneCallStatus, setPhoneCallStatus] = useState<DialoutState>();
  const [userRemoved, setUserRemoved] = useState(false);
  const [removedUserId, setRemovedUserId] = useState(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const canvasDimension = useCanvasDimension(mediaStream, videoRef);
  const activeVideo = useActiveVideo(zmClient);
  const { page, pageSize, totalPage, totalSize, setPage } = usePagination(zmClient, canvasDimension);
  const {
    visibleParticipants,
    participants,
    layout: videoLayout
  } = useGalleryLayout(zmClient, mediaStream, isVideoDecodeReady, videoRef, canvasDimension, {
    page,
    pageSize,
    totalPage,
    totalSize
  });
  /**
   * position for self video
   */
  const currentUserIndex = visibleParticipants.findIndex(
    (user) => user.userId === zmClient.getCurrentUserInfo()?.userId
  );
  let selfVideoLayout = null;
  if (currentUserIndex > -1) {
    const item = videoLayout[currentUserIndex];
    if (item && canvasDimension) {
      selfVideoLayout = { ...item, y: canvasDimension.height - item.y - item.height };
    }
  }
  const avatarActionState = useAvatarAction(zmClient, visibleParticipants);
  const networkQuality = useNetworkQuality(zmClient);

  const onHostRemoveUser = useCallback((payload: any) => {
    const { reason } = payload;
    console.log(`onHostRemoveUser`, payload);
    setRemovedUserId(payload[0].userId);
    message.error('Host has removed you.');
    setUserRemoved(true);
  }, []);

  const onPhoneCall = async (code: string, phoneNumber: string, name: string, option: DialOutOption) => {
    try {
      await mediaStream?.inviteByPhone(code, phoneNumber, name, option);
    } catch (error) {
      console.log('onPhoneCall ERR', error);
    }
  };
  const onPhoneCallCancel = async (code: string, phoneNumber: string, option: { callMe: boolean }) => {
    if ([DialoutState.Calling, DialoutState.Ringing, DialoutState.Accepted].includes(phoneCallStatus as any)) {
      try {
        await mediaStream?.cancelInviteByPhone(code, phoneNumber, option);
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve(true);
          }, 3000);
        });
      } catch (error) {
        console.log('onPhoneCallCancel ERR', error);
      }
    }
    return Promise.resolve();
  };

  useMount(() => {
    try {
      if (mediaStream) {
        console.log('mediaStream.isSupportPhoneFeature()', mediaStream?.isSupportPhoneFeature());
        // setIsSupportPhone(!!mediaStream.isSupportPhoneFeature());
        setPhoneCountryList(mediaStream.getSupportCountryInfo() || []);
      }
    } catch (error) {}
  });

  // const micOffSVG = (InfoContainDiv: any, type = 'video') => {
  //   try {
  //     var svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  //     svg.setAttribute('viewBox', '0 0 500 500');
  //     svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
  //     if (type == 'textOnly') {
  //       svg.setAttribute('width', '40px');
  //       svg.setAttribute('height', '40px');
  //     } else {
  //       svg.setAttribute('width', '20px');
  //       svg.setAttribute('height', '20px');
  //     }
  //     svg.style.margin = '3px';
  //     // Create the line element
  //     var line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  //     line.setAttribute('x1', '494.07');
  //     line.setAttribute('y1', '394.007');
  //     line.setAttribute('x2', '158.07');
  //     line.setAttribute('y2', '58.007');
  //     line.setAttribute(
  //       'style',
  //       'stroke: rgb(209, 17, 17); stroke-linecap: round; stroke-miterlimit: 10; stroke-width: 32px; fill: none;'
  //     );
  //     svg.appendChild(line);
  //     // Create the first path element
  //     var path1 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  //     path1.setAttribute(
  //       'd',
  //       'M462.07 234.007 L462.07 202.457 C462.07 193.847 455.45 186.457 446.84 186.027 C437.713 185.587 430.071 192.868 430.07 202.007 L430.07 234.007 C430.075 242.207 429.176 250.383 427.39 258.387 C427.243 259.05 427.442 259.743 427.92 260.227 L450.51 282.817 C451.542 283.848 453.294 283.464 453.8 282.097 C459.287 266.658 462.084 250.392 462.07 234.007 Z'
  //     );
  //     path1.setAttribute('style', 'fill: rgb(209, 17, 17);');
  //     svg.appendChild(path1);
  //     // Create the second path element
  //     var path2 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  //     path2.setAttribute(
  //       'd',
  //       'M318.07 346.007 C256.297 345.809 206.268 295.781 206.07 234.007 L206.07 202.457 C206.07 193.847 199.45 186.457 190.84 186.027 C181.713 185.587 174.071 192.868 174.07 202.007 L174.07 234.007 C174.07 308.007 230.17 369.127 302.07 377.117 L302.07 426.007 L254.52 426.007 C245.91 426.007 238.52 432.627 238.09 441.237 C237.651 450.364 244.932 458.006 254.07 458.007 L381.62 458.007 C390.23 458.007 397.62 451.387 398.05 442.777 C398.49 433.649 391.209 426.008 382.07 426.007 L334.07 426.007 L334.07 377.117 C352.282 375.09 369.936 369.583 386.07 360.897 C388.422 359.618 388.878 356.435 386.98 354.547 L368.58 336.157 C367.681 335.262 366.315 335.029 365.17 335.577 C350.425 342.464 334.345 346.025 318.07 346.007 Z'
  //     );
  //     path2.setAttribute('style', 'fill: rgb(209, 17, 17);');
  //     svg.appendChild(path2);
  //     // Create the third path element
  //     var path3 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  //     path3.setAttribute(
  //       'd',
  //       'M319.21 42.007 C 291.59 41.5 265.679 55.339 250.74 78.577 C 249.719 80.162 249.944 82.246 251.28 83.577 L 394.66 227.007 C 395.752 228.092 397.61 227.589 398.004 226.1 C 398.048 225.933 398.071 225.76 398.07 225.587 L 398.07 122.917 C 398.07 79.007 363.07 42.607 319.21 42.007 Z'
  //     );
  //     path3.setAttribute('style', 'fill: rgb(209, 17, 17);');
  //     svg.appendChild(path3);
  //     InfoContainDiv.appendChild(svg);
  //   } catch (error) {}
  // };

  // const micOnSVG = (InfoContainDiv: any, type = 'video') => {
  //   try {
  //     // Create the SVG element
  //     var svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  //     if (type == 'textOnly') {
  //       svg.setAttribute('width', '40px');
  //       svg.setAttribute('height', '40px');
  //     } else {
  //       svg.setAttribute('width', '20px');
  //       svg.setAttribute('height', '20px');
  //     }

  //     svg.setAttribute('viewBox', '0 0 48 48');
  //     svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
  //     svg.style.margin = '3px';
  //     // Create the title element
  //     var title = document.createElementNS('http://www.w3.org/2000/svg', 'title');
  //     title.textContent = 'mic-solid';
  //     svg.appendChild(title);
  //     // Create the 'Layer_2' group
  //     var layer2 = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  //     layer2.setAttribute('id', 'Layer_2');
  //     layer2.setAttribute('data-name', 'Layer 2');
  //     svg.appendChild(layer2);
  //     // Create the 'invisible_box' group
  //     var invisibleBox = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  //     invisibleBox.setAttribute('id', 'invisible_box');
  //     invisibleBox.setAttribute('data-name', 'invisible box');
  //     layer2.appendChild(invisibleBox);
  //     // Create the 'rect' element inside 'invisible_box'
  //     var rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
  //     rect.setAttribute('width', '48');
  //     rect.setAttribute('height', '48');
  //     rect.setAttribute('fill', 'none');
  //     invisibleBox.appendChild(rect);
  //     // Create the 'Q3_icons' group
  //     var q3Icons = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  //     q3Icons.setAttribute('id', 'Q3_icons');
  //     q3Icons.setAttribute('data-name', 'Q3 icons');
  //     layer2.appendChild(q3Icons);
  //     // Create the inner 'g' element inside 'Q3_icons'
  //     var gInner = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  //     q3Icons.appendChild(gInner);
  //     // Create the first 'path' element inside the inner 'g' element
  //     var path1 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  //     path1.setAttribute('d', 'M24,30a8,8,0,0,0,8-8V10a8,8,0,0,0-16,0V22A8,8,0,0,0,24,30Z');
  //     path1.setAttribute('fill', 'white');
  //     gInner.appendChild(path1);
  //     // Create the second 'path' element inside the inner 'g' element
  //     var path2 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  //     path2.setAttribute(
  //       'd',
  //       'M38,18a2,2,0,0,0-2,2v2a12,12,0,0,1-24,0V20a2,2,0,0,0-4,0v2A16.1,16.1,0,0,0,22,37.9V42H14a2,2,0,0,0,0,4H33a2,2,0,0,0,0-4H26V37.9A16.1,16.1,0,0,0,40,22V20A2,2,0,0,0,38,18Z'
  //     );
  //     path2.setAttribute('fill', 'white');
  //     gInner.appendChild(path2);
  //     InfoContainDiv.appendChild(svg);
  //   } catch (error) {}
  // };

  // const renderVideo = (user: any, userVideo: any, noOfParticipants: any) => {
  //   try {
  //     console.log('userVideo', userVideo);
  //     const containerDiv = document?.createElement('div');
  //     containerDiv.className = 'video-sub-container'; // Add a class for styling if needed
  //     containerDiv.id = `user-video-${user.userId}`; // Assign an id to the video element
  //     // if (noOfParticipants == 1) {
  //     //   containerDiv.style.aspectRatio = ' 16/9';
  //     //   containerDiv.style.height = '90%';
  //     // } else if (noOfParticipants > 1) {
  //     //   if (window?.innerWidth > 450) {
  //     //     if (noOfParticipants > 2) {
  //     //       containerDiv.style.flex = '0 0 30%';
  //     //     } else {
  //     //       containerDiv.style.flex = '0 0 40%';
  //     //     }

  //     //     containerDiv.style.height = '90%';
  //     //   }
  //     //   //  else {
  //     //   //   containerDiv.style.alignContent = 'space-around';
  //     //   // }
  //     // }
  //     // if (activeVideo === user.userId) {
  //     //   // userVideo.style.border = '1px solid #1890ff';
  //     // }

  //     if (noOfParticipants == 2) {
  //       containerDiv.style.flex = '1 1 49%';
  //     }
  //     // Append video element to the container div
  //     userVideo.className = 'video-player';
  //     // if (window?.innerWidth > 450 && noOfParticipants > 1) {
  //     //   userVideo.style.height = window.innerHeight / 2 + 'px';
  //     // } else {
  //     //   userVideo.style.height = '100%';
  //     // }
  //     containerDiv?.appendChild(userVideo);
  //     // Create a text element
  //     const textElement = document?.createElement('h3');
  //     if (user?.isHost) {
  //       textElement.textContent = `${user?.displayName} (Host)`; // Set the text content
  //     } else {
  //       if (user?.userId == zmClient?.getCurrentUserInfo()?.userId) {
  //         textElement.textContent = `${user?.displayName} (You)`;
  //       } else {
  //         textElement.textContent = `${user?.displayName}`;
  //       }
  //       // textElement.style.containerType = 'inline-size';
  //       // textElement.style.textAlign = 'left';
  //     }

  //     textElement.className = 'video-text'; // Add a class for styling if needed
  //     const InfoContainDiv = document?.createElement('div');
  //     InfoContainDiv.className = 'video-info';

  //     if (user?.audio === 'computer' && user?.muted) {
  //       micOffSVG(InfoContainDiv);
  //     } else {
  //       micOnSVG(InfoContainDiv);
  //     }

  //     InfoContainDiv?.appendChild(textElement);
  //     containerDiv?.appendChild(InfoContainDiv);
  //     document?.querySelector('video-player-container')?.appendChild(containerDiv);
  //     // Try playing the video after it has been appended
  //     // try {
  //     //   userVideo.play();
  //     // } catch (playError) {
  //     //   console.log('Error playing video:', playError);
  //     // }
  //   } catch (error) {
  //     console.log('renderVideo ERR', error);
  //   }
  // };

  // const renderTextOnly = (user: any, noOfParticipants: any) => {
  //   try {
  //     const containerDiv = document.createElement('div');
  //     containerDiv.className = 'video-sub-container'; // Add a class for styling if needed
  //     containerDiv.id = `user-video-${user.userId}`; // Assign an id to the video element
  //     containerDiv.style.backgroundColor = '#161716';

  //     if (noOfParticipants >= 3) {
  //       // containerDiv.style.aspectRatio = '12/4';
  //     }
  //     // Create a text element
  //     const textElement = document?.createElement('h3');
  //     if (user?.isHost) {
  //       textElement.textContent = `${user?.displayName} (Host)`; // Set the text content
  //     } else if (user?.userId == zmClient?.getCurrentUserInfo()?.userId) {
  //       textElement.textContent = `${user?.displayName} (Host)`; // Set the text content
  //     } else {
  //       textElement.textContent = `${user?.displayName}`;
  //     }
  //     textElement.className = 'video-text'; // Add a class for styling if needed
  //     textElement.style.fontSize = '30px';
  //     textElement.style.left = '45%';

  //     const InfoContainDiv = document?.createElement('div');
  //     InfoContainDiv.className = 'video-info';

  //     if (user?.audio === 'computer' && user?.muted) {
  //       micOffSVG(InfoContainDiv, 'textOnly');
  //     } else {
  //       micOnSVG(InfoContainDiv, 'textOnly');
  //     }
  //     // InfoContainDiv.style.position = 'sticky';
  //     // InfoContainDiv.style.top = '40%';

  //     InfoContainDiv?.appendChild(textElement);
  //     containerDiv?.appendChild(InfoContainDiv);

  //     // Append the text element to the container div
  //     // containerDiv?.appendChild(textElement);
  //     document?.querySelector('video-player-container')?.appendChild(containerDiv);
  //   } catch (error) {}
  // };

  // const removeVideoContainer = (userId: any) => {
  //   try {
  //     const containerDiv: any = document?.getElementById(`user-video-${userId}`);
  //     containerDiv?.remove();
  //     if (containerDiv) {
  //       containerDiv?.parentNode?.removeChild(containerDiv); // Remove the container from the DOM
  //     }
  //   } catch (error) {}
  // };

  // useEffect(() => {
  //   try {
  //     console.log('PARTICIPANTS', participants);
  //     const existingUserContainers = Array.from(document?.querySelectorAll('.video-sub-container'));
  //     const existingUserIds = new Set(existingUserContainers?.map((div) => div?.id?.split('-').pop()));
  //     const participantIds = new Set(participants?.map((participant) => String(participant?.userId)));

  //     // Remove video containers for users no longer in the participants list
  //     existingUserIds.forEach((id: any) => {
  //       if (!participantIds?.has(id)) {
  //         removeVideoContainer(id);
  //       }
  //     });

  //     participants
  //       .sort((a, b) => a.userId - b.userId)
  //       .forEach((user) => {
  //         const existingContainer = document?.getElementById(`user-video-${user.userId}`);
  //         if (!existingContainer) {
  //           if (user?.bVideoOn && !user.isInFailover) {
  //             mediaStream
  //               ?.attachVideo(user.userId, 4)
  //               .then((userVideo) => renderVideo(user, userVideo, participants?.length))
  //               .catch(() => {});
  //           } else if (!user.bVideoOn) {
  //             renderTextOnly(user, participants?.length);
  //           }
  //         } else {
  //           mediaStream
  //             ?.detachVideo(user.userId)
  //             .then(() => {
  //               removeVideoContainer(user.userId);
  //               if (user?.bVideoOn && !user.isInFailover) {
  //                 mediaStream
  //                   ?.attachVideo(user.userId, 4)
  //                   .then((userVideo) => renderVideo(user, userVideo, participants?.length))
  //                   .catch(() => {});
  //               } else if (!user.bVideoOn) {
  //                 renderTextOnly(user, participants?.length);
  //               }
  //             })
  //             .catch(() => {});
  //         }
  //       });
  //   } catch (error) {
  //     console.log('UseEffect Video Error', error);
  //   }
  // }, [participants, zmClient]);

  return (
    <div className="viewport">
      <ShareView ref={shareViewRef} onRecieveSharingChange={setIsRecieveSharing} />
      {/* <video-player-container></video-player-container> */}

      <div
        className={classnames('video-container', {
          'video-container-in-sharing': isRecieveSharing
        })}
      >
        <canvas className="video-canvas" id="video-canvas" width="800" height="600" ref={videoRef} />
        {selfVideoLayout && mediaStream?.isRenderSelfViewWithVideoElement() && (
          <video
            id={SELF_VIDEO_ID}
            className="self-video-tag"
            playsInline
            // muted
            autoPlay
            style={{
              display: 'block',
              width: `${selfVideoLayout?.width}px`,
              height: `${selfVideoLayout?.height}px`,
              top: `${selfVideoLayout?.y}px`,
              left: `${selfVideoLayout?.x}px`,
              pointerEvents: 'none'
            }}
          />
        )}
        <AvatarActionContext.Provider value={avatarActionState}>
          <ul className="avatar-list">
            {visibleParticipants
              ?.sort((a, b) => a.userId - b.userId)
              ?.map((user, index) => {
                if (index > videoLayout.length - 1) {
                  return null;
                }
                const dimension = videoLayout[index];
                const { width, height, x, y } = dimension;
                const { height: canvasHeight } = canvasDimension;
                return (
                  <Avatar
                    participant={user}
                    key={user.userId}
                    isActive={activeVideo === user.userId}
                    networkQuality={networkQuality[`${user.userId}`]}
                    style={{
                      width: `${width}px`,
                      height: `${height}px`,
                      top: `${canvasHeight - y - height}px`,
                      left: `${x}px`
                    }}
                  />
                );
              })}
          </ul>
          <RemoteCameraControlPanel />
        </AvatarActionContext.Provider>
      </div>
      <VideoFooter
        className="video-operations"
        sharing
        selfShareCanvas={shareViewRef.current?.selfShareRef}
        isChat={isChat}
        setIsChat={setIsChat}
        showParticipants={showParticipants}
        setShowParticipants={setShowParticipants}
        showSmsModal={showSmsModal}
        setShowSmsModal={setShowSmsModal}
        showPatientCallSmsModal={showPatientCallSmsModal}
        setShowPatientCallSmsModal={setShowPatientCallSmsModal}
        showPhoneCallModal={showPhoneCallModal}
        setShowPhoneCallModal={setShowPhoneCallModal}
        setIsLoading={props?.props?.setIsLoading}
        setLoadingText={props?.props?.setLoadingText}
        setRefresh={props?.props?.setRefresh}
      />

      {/* {totalPage > 1 && <Pagination page={page} totalPage={totalPage} setPage={setPage} inSharing={isRecieveSharing} />} */}
      {/* <ReportBtn /> */}

      <ChatModal isChat={isChat} setIsChat={setIsChat} />

      <Participants
        showParticipants={showParticipants}
        setShowParticipants={setShowParticipants}
        mediaStream={mediaStream}
        isVideoDecodeReady={isVideoDecodeReady}
        videoRef={videoRef}
        canvasDimension={canvasDimension}
        zmClient={zmClient}
      />

      <Sms
        showSmsModal={showSmsModal}
        setShowSmsModal={setShowSmsModal}
        mediaStream={mediaStream}
        isVideoDecodeReady={isVideoDecodeReady}
        videoRef={videoRef}
        canvasDimension={canvasDimension}
        zmClient={zmClient}
      />

      <PatientCallSms
        showPatientCallSmsModal={showPatientCallSmsModal}
        setShowPatientCallSmsModal={setShowPatientCallSmsModal}
        mediaStream={mediaStream}
        isVideoDecodeReady={isVideoDecodeReady}
        videoRef={videoRef}
        canvasDimension={canvasDimension}
        zmClient={zmClient}
        onPhoneCallClick={onPhoneCall}
        phoneCallStatus={getPhoneCallStatusDescription(phoneCallStatus)}
      />

      <CallOutModal
        visible={showPhoneCallModal}
        setVisible={(visible: boolean) => setShowPhoneCallModal(visible)}
        phoneCallStatus={getPhoneCallStatusDescription(phoneCallStatus)}
        phoneCountryList={phoneCountryList}
        onPhoneCallCancel={onPhoneCallCancel}
        onPhoneCallClick={onPhoneCall}
      />

      {/* {isChat && */}
      {/* <Modal
        open={isChat}
        // className="video-mask-setting-dialog"
        title="Chat"
        footer={null}
        closable
        onCancel={() => setIsChat(false)}
        width={270}
      >
        {' '}
        <Chat />
      </Modal> */}
      {/* } */}
    </div>
  );
};

export default VideoContainer;
