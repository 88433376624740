// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-mask-setting-dialog .video-preview {
  width: 520px;
  height: 292px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}
.video-mask-setting-dialog .video-preview-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-mask-setting-dialog .video-clip {
  position: absolute;
  width: 208px;
  height: 208px;
  top: 0;
  left: 0;
}
.video-mask-setting-dialog .video-clip:hover {
  border: 1px solid #4477CE;
  cursor: move;
}
.video-mask-setting-dialog .video-background-list {
  border: none;
  display: flex;
}
.video-mask-setting-dialog .video-background-list .video-background-item {
  width: 120px;
  height: 68px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333;
  color: #f5f5f5;
  font-size: 14px;
  font-weight: 700;
  margin-right: 5px;
  padding: 0;
  overflow: hidden;
}
.video-mask-setting-dialog .video-background-list .video-background-item .ant-menu-title-content {
  width: 120px;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-mask-setting-dialog .video-background-list .video-background-item img {
  width: 100%;
  max-height: 100%;
}
.video-mask-setting-dialog .video-background-list .video-background-item__active {
  border: 1px solid #F86F03;
}`, "",{"version":3,"sources":["webpack://./src/feature/video/components/video-mask-modal.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;AAAJ;AAEE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;AAAJ;AAEE;EACE,kBAAA;EACA,YAAA;EACA,aAAA;EACA,MAAA;EACA,OAAA;AAAJ;AACI;EACE,yBAAA;EACA,YAAA;AACN;AAEE;EACE,YAAA;EACA,aAAA;AAAJ;AACI;EACE,YAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,UAAA;EACA,gBAAA;AACN;AAAM;EACE,YAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAER;AAAM;EACE,WAAA;EACA,gBAAA;AAER;AAAM;EACE,yBAAA;AAER","sourcesContent":[".video-mask-setting-dialog {\n  .video-preview{\n    width: 520px;\n    height: 292px;\n    border-radius: 6px;\n    overflow: hidden;\n    position: relative;\n  }\n  .video-preview-canvas {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n  }\n  .video-clip{\n    position: absolute;\n    width: 208px;\n    height: 208px;\n    top:0;\n    left: 0;\n    &:hover {\n      border: 1px solid #4477CE;\n      cursor: move;\n    }\n  }\n  .video-background-list {\n    border: none;\n    display: flex;\n    & .video-background-item{\n      width: 120px;\n      height: 68px;\n      border-radius: 4px;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      background-color: #333;\n      color: #f5f5f5;\n      font-size: 14px;\n      font-weight: 700;\n      margin-right: 5px;\n      padding: 0;\n      overflow: hidden;\n      & .ant-menu-title-content {\n        width: 120px;\n        height: 68px;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n      }\n      & img{\n        width: 100%;\n        max-height: 100%;\n      }\n      &__active {\n        border: 1px solid #F86F03;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
