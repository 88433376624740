import React from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import { IconFont } from '../../../component/icon-font';

interface PatientAddButtonProps {
  onPatientAddClick: () => void;
}

const PatientAddButton = (props: PatientAddButtonProps) => {
  const { onPatientAddClick } = props;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment

    <Button
      className={classNames('screen-share-button', 'vc-button')}
      icon={<IconFont type="icon-patient-add" />}
      ghost={true}
      shape="circle"
      size="large"
      onClick={onPatientAddClick}
    />
  );
};

export { PatientAddButton };
