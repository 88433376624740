import axios from 'axios';
import { BASE_URL_LOCAL, BASE_URL_SERVER } from '../config/dev';

const saveUserInDb = async (data: object) => {
  try {
    const response = await axios.post(`${BASE_URL_SERVER}/user`, data);
    console.log('saveUserInDb response', response.data);
    return response.data;
  } catch (error: any) {
    // console.error('saveUserInDb error', error);
    return error?.response?.data;
    // throw new Error('Failed to fetch user data from the server');
  }
};

const getUserFromDb = async (id: string) => {
  try {
    const response = await axios.get(`${BASE_URL_SERVER}/user/${id}`);
    console.log('getUserFromDb response', response.data);
    return response.data;
  } catch (error: any) {
    // console.error('getUserFromDb error', error);
    return error?.response?.data;
    // throw new Error('Failed to fetch user data from the server');
  }
};

const saveMeetingInDb = async (data: object) => {
  try {
    const response = await axios.post(`${BASE_URL_SERVER}/meeting`, data);
    console.log('saveMeetingInDb response', response.data);
    return response.data;
  } catch (error: any) {
    // console.error('saveUserInDb error', error);
    return error?.response?.data;
    // throw new Error('Failed to fetch user data from the server');
  }
};

const updateMeetingInDb = async (id: string, data: object) => {
  try {
    const response = await axios.put(`${BASE_URL_SERVER}/meeting/${id}`, data);
    console.log('updateMeetingInDb response', response.data);
    return response.data;
  } catch (error: any) {
    // console.error('saveUserInDb error', error);
    return error?.response?.data;
    // throw new Error('Failed to fetch user data from the server');
  }
};

const getMeetingFromDb = async (id: string) => {
  try {
    const response = await axios.get(`${BASE_URL_SERVER}/meeting/${id}`);
    console.log('getMeetingFromDb response', response.data);
    return response.data;
  } catch (error: any) {
    // console.error('getUserFromDb error', error);
    return error?.response?.data;
    // throw new Error('Failed to fetch user data from the server');
  }
};

const getUserByAccessToken = async (accessToken: string) => {
  // let data
  // let data = {
  //   sub: 'https://test.salesforce.com/id/00DVC000000HwNl2AK/005VC000003dhDGYAY',
  //   user_id: '005VC000003dhDGYAY',
  //   organization_id: '00DVC000000HwNl2AK',
  //   preferred_username: 'adnan@vertexagency.net.jscdev',
  //   nickname: 'User17121873597921434840',
  //   name: 'Adnan Hatim',
  //   email: 'adnan@vertexagency.net'
  // };
  try {
    const response = await axios.get(`${BASE_URL_SERVER}/user-info/${accessToken}`);
    console.log('getUserFromSalesforce response', response?.data);
    return response?.data;
  } catch (error: any) {
    // console.error('getUserFromDb error', error);
    return error;
    // throw new Error('Failed to fetch user data from the server');
  }
};

const getAccessTokenFromRefreshToken = async (refresh_token: string) => {
  // let data
  // let data = {
  //   access_token:
  //     '00DVC000000HwNl!AQEAQLxLj.LKRZosllUbacEu9M5nR1n3vzxQbHhQ0DkfMKSXfNzoM4xwXBoiCmwX9nGLr1op8YAGpmNSXpsjxjo7YJkH9tEU',
  //   refresh_token: '5Aep861NZD_RZj0ZDImRucDB41EdvvQ2i6AjlYsC6he8.Q34buIgsOWLS6L05JzIFb6eK_E4IEIoA==',
  //   signature: 'tyXR5xiBsTSrRqD7UtaNxrK6ysibypXfGLUwWW9H7qU=',
  //   scope: 'refresh_token full',
  //   instance_url: 'https://jobsitecare--jscdev.sandbox.my.salesforce.com',
  //   id: 'https://test.salesforce.com/id/00DVC000000HwNl2AK/005VC000003dhDGYAY',
  //   token_type: 'Bearer',
  //   issued_at: '1714032374406'
  // };
  try {
    // const response = await axios.get(
    //   `https://test.salesforce.com/services/oauth2/token?grant_type=refresh_token&client_id=3MVG9L6vUmMQkenPtWxRP41ZWcTlxG0hOo51xwvEXDNF8.dxs_wsQE.JcEnm2B.WFRkGVOdHsvC6eosrvhYl1&client_secret=0B5D17B3A18C4EE08A76A0EC5414D788DE1ED0D917FBC35304036312484DBA0C&refresh_token=${refresh_token}`
    // );
    const response = await axios.get(`${BASE_URL_SERVER}/access-token/${refresh_token}`);
    console.log('getAccessTokenFromRefreshToken response', response.data);
    return response?.data;
  } catch (error: any) {
    // console.error('getUserFromDb error', error);
    return error;
    // throw new Error('Failed to fetch user data from the server');
  }
};

const getTokensByCode = async (code: string) => {
  // let data
  // let data = {
  //   access_token:
  //     '00DVC000000HwNl!AQEAQLxLj.LKRZosllUbacEu9M5nR1n3vzxQbHhQ0DkfMKSXfNzoM4xwXBoiCmwX9nGLr1op8YAGpmNSXpsjxjo7YJkH9tEU',
  //   refresh_token: '5Aep861NZD_RZj0ZDImRucDB41EdvvQ2i6AjlYsC6he8.Q34buIgsOWLS6L05JzIFb6eK_E4IEIoA==',
  //   signature: 'tyXR5xiBsTSrRqD7UtaNxrK6ysibypXfGLUwWW9H7qU=',
  //   scope: 'refresh_token full',
  //   instance_url: 'https://jobsitecare--jscdev.sandbox.my.salesforce.com',
  //   id: 'https://test.salesforce.com/id/00DVC000000HwNl2AK/005VC000003dhDGYAY',
  //   token_type: 'Bearer',
  //   issued_at: '1714032374406'
  // };
  try {
    // const response = await axios.get(
    //   `https://test.salesforce.com/services/oauth2/token?grant_type=authorization_code&code=${code}&client_id=3MVG9L6vUmMQkenPtWxRP41ZWcTlxG0hOo51xwvEXDNF8.dxs_wsQE.JcEnm2B.WFRkGVOdHsvC6eosrvhYl1&client_secret=0B5D17B3A18C4EE08A76A0EC5414D788DE1ED0D917FBC35304036312484DBA0C&redirect_uri=https://zoom.jobsitecare.com/host`
    // );
    const response = await axios.post(`${BASE_URL_SERVER}/auth/${code}`);
    console.log('getTokensByCode response', response.data);
    return response?.data;
  } catch (error: any) {
    // console.error('getUserFromDb error', error);
    return error;
    // throw new Error('Failed to fetch user data from the server');
  }

  // try {
  //   const response = await axios.get(
  //     `http://localhost:8081/auth/${code}`
  //     // {
  //     //   headers: {
  //     //     mode: 'cors'
  //     //   }
  //     // }
  //   );
  //   console.log('getTokensByCode response', response);
  //   return response;
  // } catch (error: any) {
  //   // console.error('getUserFromDb error', error);
  //   return error;
  //   // throw new Error('Failed to fetch user data from the server');
  // }
};

const getPatientInfoByRecordId = async (recordId: string, access_token: string) => {
  console.log('getPatientInfoByRecordId', recordId, access_token);
  // let data
  // let data = {
  //     "success": true,
  //     "phoneNumber": "910900216",
  //     "message": "Primary phone number retrieved successfully.",
  //     "contactName": "Scenerio 3"
  // }
  try {
    const response = await axios.get(
      `https://jobsitecare--jscdev.sandbox.my.salesforce.com/services/apexrest/ContactNumberService/${recordId}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      }
    );
    console.log('getPatientInfoByRecordId response', response.data);
    return response;
  } catch (error: any) {
    console.error('getPatientInfoByRecordId error', error);
    return error;
    // throw new Error('Failed to fetch user data from the server');
  }
};

const updateCallStatus = async (meetingId: string, accessToken: string, status: string, link: string) => {
  // console.log('updateCallStatus', meetingId, accessToken, status, link);
  // Parse the URL
  const urlObj = new URL(link);
  // Get the search parameters
  const params = new URLSearchParams(urlObj.search);

  try {
    // const body = {
    //   recordId: 'a1yVC0000008OLJYA2',
    //   status: 'In Progress',
    //   roomId: 'https://zoom.jobsitecare.com/host?userId=asq12&recordId=a1yVC0000008OLJYA2',
    //   roomName: 'a1yVC0000008OLJYA21716913839023'
    // };
    const body = {
      recordId: params.get('recordId'),
      status: status,
      roomId: meetingId,
      roomName: ''
    };

    console.log('updateCallStatus', body);

    const response = await axios.post(
      `https://jobsitecare--jscdev.sandbox.my.salesforce.com/services/apexrest/CallStatus`,
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    console.log('CallStatus response', response.data);
    return response;
  } catch (error: any) {
    console.error('CallStatus error', error);
    return error;
    // throw new Error('Failed to fetch user data from the server');
  }
};

export {
  saveUserInDb,
  getUserFromDb,
  saveMeetingInDb,
  updateMeetingInDb,
  getMeetingFromDb,
  getUserByAccessToken,
  getAccessTokenFromRefreshToken,
  getTokensByCode,
  getPatientInfoByRecordId,
  updateCallStatus
};
