// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.viewport {
  position: relative;
  padding: 0 !important;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  text-align: center;
  overflow: hidden;
}
.viewport h1 {
  color: white;
  font-size: 70px;
  text-align: center;
  vertical-align: middle;
  padding: 5%;
}`, "",{"version":3,"sources":["webpack://./src/component/screens/participant-removed.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qBAAA;EACA,sBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EAEA,gBAAA;AAAF;AACE;EACE,YAAA;EACA,eAAA;EACA,kBAAA;EACA,sBAAA;EACA,WAAA;AACJ","sourcesContent":[".viewport {\n  position: relative;\n  padding: 0 !important;\n  box-sizing: border-box;\n  width: 100vw;\n  height: 100vh;\n  background-color: #000;\n  text-align: center;\n  //   display: flex;\n  overflow: hidden;\n  h1 {\n    color: white;\n    font-size: 70px;\n    text-align: center;\n    vertical-align: middle;\n    padding: 5%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
