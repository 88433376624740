import React from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import { IconFont } from '../../../component/icon-font';

interface PhoneCallBtnProps {
  onPhoneCallClick: () => void;
}

const PhoneCallBtn = (props: PhoneCallBtnProps) => {
  const { onPhoneCallClick } = props;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment

    <Button
      className={classNames('screen-share-button', 'vc-button')}
      icon={<IconFont type="icon-phone" />}
      ghost={true}
      shape="circle"
      size="large"
      onClick={onPhoneCallClick}
    />
  );
};

export { PhoneCallBtn };
