// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-receiver {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 25px;
  margin: 15px 0;
}
.chat-receiver .chat-to {
  margin-right: 5px;
}

.chat-receiver-dropdown-menu .ant-dropdown-menu-item {
  padding: 10px 30px;
  position: relative;
}
.chat-receiver-dropdown-menu .ant-dropdown-menu-item .anticon-check {
  position: absolute;
  left: 10px;
  top: 15px;
}
.chat-receiver-dropdown-menu .ant-dropdown-menu-item-group-title {
  font-weight: 700;
  color: #000;
}
.chat-receiver-dropdown-menu .chat-receiver-item-affix {
  color: #333;
  margin-left: 5px;
}

.chat-send-file-input {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/feature/chat/component/chat-receiver.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,YAAA;EACA,cAAA;AACF;AAAE;EACE,iBAAA;AAEJ;;AAGE;EACE,kBAAA;EACA,kBAAA;AAAJ;AACI;EACE,kBAAA;EACA,UAAA;EACA,SAAA;AACN;AAEE;EACE,gBAAA;EACA,WAAA;AAAJ;AAEE;EACE,WAAA;EACA,gBAAA;AAAJ;;AAIA;EACE,aAAA;AADF","sourcesContent":[".chat-receiver {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 20px;\n  height: 25px;\n  margin: 15px 0;\n  .chat-to {\n    margin-right: 5px;\n  }\n  \n}\n.chat-receiver-dropdown-menu{\n  .ant-dropdown-menu-item {\n    padding: 10px 30px;\n    position: relative;\n    .anticon-check {\n      position: absolute;\n      left: 10px;\n      top: 15px;\n    }\n  }\n  .ant-dropdown-menu-item-group-title {\n    font-weight: 700;\n    color: #000;\n  }\n  .chat-receiver-item-affix{\n    color:#333;\n    margin-left: 5px;\n  }\n}\n\n.chat-send-file-input{\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
