// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.screen-share-control-bar {
  color: #fff;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  border-radius: 14px;
  background-color: rgba(100, 100, 100, 0.6);
  top: 2vh;
  max-width: 80vw;
  position: fixed;
  left: 30%;
  z-index: 1;
}
.screen-share-control-bar .ant-btn-icon-only {
  width: 26px;
  height: 26px;
  border: none;
  color: #fff;
  border-radius: 5px;
}
.screen-share-control-bar .ant-btn-icon-only:hover {
  background: #ffffff17;
}
.screen-share-control-bar .ant-btn-icon-only:active {
  background: #ffffff2e;
}
.screen-share-control-bar .share-bar-move {
  cursor: move;
}
.screen-share-control-bar .share-bar-move:hover, .screen-share-control-bar .share-bar-move:active {
  background: none;
}
.screen-share-control-bar .share-bar-tip {
  padding: 0 30px;
}
.screen-share-control-bar .share-bar-canvas {
  width: 47px;
  height: 28px;
  border-radius: 5px;
  border: 1px solid #23d959;
  margin-right: 10px;
}
.screen-share-control-bar .share-bar-btn {
  margin-right: 10px;
}
.screen-share-control-bar .share-bar-more {
  width: auto;
}

.share-bar-hide {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/feature/video/components/share-bar.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,0CAAA;EACA,QAAA;EACA,eAAA;EACA,eAAA;EACA,SAAA;EACA,UAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;AAEJ;AADI;EACE,qBAAA;AAGN;AADI;EACE,qBAAA;AAGN;AAAE;EACE,YAAA;AAEJ;AADI;EACE,gBAAA;AAGN;AAAE;EACE,eAAA;AAEJ;AAAE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,kBAAA;AAEJ;AAAE;EACE,kBAAA;AAEJ;AAAE;EACE,WAAA;AAEJ;;AACA;EACE,aAAA;AAEF","sourcesContent":[".screen-share-control-bar {\n  color: #fff;\n  padding: 10px 16px;\n  display: flex;\n  align-items: center;\n  border-radius: 14px;\n  background-color: rgba(100,100,100,0.6);\n  top: 2vh;\n  max-width: 80vw;\n  position: fixed;\n  left: 30%;\n  z-index: 1;\n  .ant-btn-icon-only {\n    width: 26px;\n    height: 26px;\n    border: none;\n    color: #fff;\n    border-radius: 5px;\n    &:hover {\n      background: #ffffff17;\n    }\n    &:active {\n      background: #ffffff2e;\n    }\n  }\n  .share-bar-move {\n    cursor: move;\n    &:hover,&:active{\n      background:none\n    }\n  }\n  .share-bar-tip {\n    padding: 0 30px;\n  }\n  .share-bar-canvas {\n    width: 47px;\n    height: 28px;\n    border-radius: 5px;\n    border: 1px solid #23d959;\n    margin-right: 10px;\n  }\n  .share-bar-btn{\n    margin-right: 10px;\n  }\n  .share-bar-more {\n    width: auto;\n  }\n}\n.share-bar-hide{\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
