// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialer-pad {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  padding: 10px;
}
.dialer-pad .row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.dialer-pad .row button {
  width: 30%;
  padding: 13px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  background-color: #c9c9c9;
  cursor: pointer;
}
.dialer-pad .row button:hover {
  background-color: #58acfa;
}
.dialer-pad .row .button-send {
  background-color: #58acfa;
  width: 100%;
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/feature/video/components/sms.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,cAAA;EACA,yBAAA;EACA,kBAAA;EACA,aAAA;AACF;AACE;EACE,aAAA;EACA,8BAAA;EACA,kBAAA;AACJ;AACI;EACE,UAAA;EACA,aAAA;EACA,iBAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,eAAA;AACN;AACM;EACE,yBAAA;AACR;AAGI;EACE,yBAAA;EACA,WAAA;EACA,mBAAA;AADN","sourcesContent":[".dialer-pad {\n  width: 100%;\n  margin: 0 auto;\n  border: 1px solid rgb(201, 201, 201);\n  border-radius: 5px;\n  padding: 10px;\n\n  .row {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 5px;\n\n    button {\n      width: 30%;\n      padding: 13px;\n      font-size: 1.2rem;\n      border: none;\n      border-radius: 5px;\n      background-color: rgb(201, 201, 201);\n      cursor: pointer;\n\n      &:hover {\n        background-color: #58acfa;\n      }\n    }\n\n    .button-send {\n      background-color: #58acfa;\n      width: 100%;\n      border-radius: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
