import { useEffect, useContext, useState, useCallback, useReducer, useMemo } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ZoomVideo, { ConnectionState, ReconnectReason } from '@zoom/videosdk';
import { message, Modal } from 'antd';
import 'antd/dist/antd.min.css';
import produce from 'immer';
import Home from './feature/home/home';
import Video from './feature/video/video';
import VideoSingle from './feature/video/video-single';
import VideoAttach from './feature/video/video-attach';
import Preview from './feature/preview/preview';
import ZoomContext from './context/zoom-context';
import ZoomMediaContext from './context/media-context';
import LoadingLayer from './component/loading-layer';
import ParticipantRemoved from './component/screens/participant-removed';
import UserLeft from './component/screens/user-left';
import Chat from './feature/chat/chat';
import Command from './feature/command/command';
import Subsession from './feature/subsession/subsession';
import { MediaStream } from './index-types';
import './App.css';
import VideoHost from './feature/video/video-host';
import axios from 'axios';
import { generateVideoToken } from './utils/util';
import {
  getAccessTokenFromRefreshToken,
  getMeetingFromDb,
  getPatientInfoByRecordId,
  getTokensByCode,
  getUserByAccessToken,
  getUserFromDb,
  saveMeetingInDb,
  saveUserInDb,
  updateCallStatus,
  updateMeetingInDb
} from './utils/api';

interface AppProps {
  meetingArgs: {
    sdkKey: string;
    sdkSecret: string;
    topic: string;
    signature: string;
    name: string;
    password?: string;
    webEndpoint?: string;
    enforceGalleryView?: string;
    enforceVB?: string;
    customerJoinId?: string;
    lang?: string;

    useVideoPlayer?: string;
  };
}
const mediaShape = {
  audio: {
    encode: false,
    decode: false
  },
  video: {
    encode: false,
    decode: false
  },
  share: {
    encode: false,
    decode: false
  }
};
const mediaReducer = produce((draft, action) => {
  switch (action.type) {
    case 'audio-encode': {
      draft.audio.encode = action.payload;
      break;
    }
    case 'audio-decode': {
      draft.audio.decode = action.payload;
      break;
    }
    case 'video-encode': {
      draft.video.encode = action.payload;
      break;
    }
    case 'video-decode': {
      draft.video.decode = action.payload;
      break;
    }
    case 'share-encode': {
      draft.share.encode = action.payload;
      break;
    }
    case 'share-decode': {
      draft.share.decode = action.payload;
      break;
    }
    case 'reset-media': {
      Object.assign(draft, { ...mediaShape });
      break;
    }
    default:
      break;
  }
}, mediaShape);

declare global {
  interface Window {
    webEndpoint: string | undefined;
    zmClient: any | undefined;
    mediaStream: any | undefined;
    crossOriginIsolated: boolean;
    ltClient: any | undefined;
    logClient: any | undefined;
  }
}

// function useQuery() {
//   const { search } = useLocation();

//   return useMemo(() => new URLSearchParams(search), [search]);
// }

function App(props: AppProps) {
  const {
    meetingArgs: {
      sdkKey,
      sdkSecret,
      topic,
      signature,
      name,
      password,
      webEndpoint: webEndpointArg,
      enforceGalleryView,
      enforceVB,
      customerJoinId,
      lang,
      useVideoPlayer
    }
  } = props;
  // let query = useQuery();
  console.log('location', location);
  const urlParams = new URLSearchParams(location?.search);
  const roomId: string = urlParams.get('roomId') || '';

  // For Patient
  const namePatient: string = decodeURIComponent(urlParams.get('name') || '');
  const numberPatient: string = urlParams.get('name') || '';
  const meetingIdPatient: string = urlParams.get('meetingId') || '';

  // const signatureParticipant: string = urlParams.get('signature') || '';

  // For Host
  const userIdParam: string = urlParams.get('userId') || '';
  const recordIdParam: string = urlParams.get('recordId') || '';

  const SFCode: string = urlParams.get('code') || '';

  // after Salesforce Redirect
  const urlHash = new URLSearchParams(window.location.hash.substring(1));
  const accessTokenHash = encodeURIComponent(urlHash.get('access_token') || '');
  const refreshTokenHash = encodeURIComponent(urlHash.get('refresh_token') || '');

  const typeParam = location?.pathname?.substring(1);
  const [loading, setIsLoading] = useState(true);
  const [loadingText, setLoadingText] = useState('');
  const [isFailover, setIsFailover] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('closed');
  const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [isSupportGalleryView, setIsSupportGalleryView] = useState<boolean>(false);
  const [participantRemoved, setParticipantRemoved] = useState<boolean>(false);
  const [userLeft, setUserLeft] = useState<boolean>(false);
  const [isHostNotFound, setIsHostNotFound] = useState<boolean>(false);

  const [refresh, setRefresh] = useState<number>(1);

  const zmClient = useContext(ZoomContext);
  let webEndpoint: any;
  if (webEndpointArg) {
    webEndpoint = webEndpointArg;
  } else {
    webEndpoint = window?.webEndpoint ?? 'zoom.us';
  }
  const mediaContext = useMemo(() => ({ ...mediaState, mediaStream }), [mediaState, mediaStream]);
  const galleryViewWithoutSAB = Number(enforceGalleryView) === 1 && !window.crossOriginIsolated;
  const vbWithoutSAB = Number(enforceVB) === 1 && !window.crossOriginIsolated;
  const galleryViewWithAttach = Number(useVideoPlayer) === 1 && (window.crossOriginIsolated || galleryViewWithoutSAB);

  const [AccessToken, setAccessToken] = useState<string>('null');
  const [userInfo, setUserInfo] = useState<any>(null);
  let joiningArgs;
  // let location = useLocation();

  function addHoursToDate(myDate: any, hours: number) {
    myDate.setHours(myDate.getHours() + hours);
  }

  const extractAccessToken = (url: string): any => {
    const regex = /access_token=([^&]*)/;
    const match = regex.exec(url);
    return match ? match[1] : null;
  };

  const getUserInfo = async (accessTokenLocal: string) => {
    const info = {
      sub: 'https://test.salesforce.com/id/00DVC000000HwNl2AK/005VC000003dhDGYAY',
      user_id: '005VC000003dhDGYAY',
      organization_id: '00DVC000000HwNl2AK',
      preferred_username: 'adnan@vertexagency.net.jscdev',
      nickname: 'User17121873597921434840',
      name: 'Adnan Hatim',
      email: 'adnan@vertexagency.net'
    };
    // setUserInfo(info)

    return await info;
    // setUserInfo(info);
    // if (accessTokenLocal) {
    //   await axios
    //     .get(`https://test.salesforce.com/services/oauth2/userinfo?oauth_token=${accessTokenLocal}`)
    //     .then(async (response: any) => {
    //       console.log('USERINFO', response);
    //     })
    //     .catch((error: any) => {
    //       console.log('error', error);
    //     });
    // }
  };

  const joinZoomMeeting = async (data: any) => {
    // if (data?.type == 'host') {
    //   console.log('data?.accessToken', data?.accessToken);
    //   const body = {
    //     recordId: 'a1yVC0000008OLJYA2',
    //     status: 'In Progress',
    //     roomId: 'https://zoom.jobsitecare.com/host?userId=asq12&recordId=a1yVC0000008OLJYA2',
    //     roomName: 'a1yVC0000008OLJYA21716913839023'
    //   };
    //   const config = {
    //     headers: {
    //       Authorization: `Bearer ${data?.accessToken}`
    //     }
    //   };
    //   await axios
    //     .post(`https://jobsitecare--jscdev.sandbox.my.salesforce.com/services/apexrest/CallStatus`, body, config)
    //     .then(async (response: any) => {
    //       console.log('CallStatus res', response);
    //     })
    //     .catch((error: any) => {
    //       console.log('CallStatus error', error);
    //     });
    // }

    const joiningArgs = {
      sdkKey,
      sdkSecret,
      sessionKey: '',
      userIdentity: '',
      topic: data?.meeting_id,
      name: data?.userName,
      role: data?.type == 'host' ? 1 : 0,
      cloud_recording_option: '',
      cloud_recording_election: '',
      telemetry_tracking_id: '',
      password: '',
      signature: ''
    };
    console.log(
      'joinZoomMeeting',
      joiningArgs.sdkKey,
      joiningArgs.sdkSecret,
      joiningArgs.topic,
      joiningArgs.sessionKey,
      joiningArgs.userIdentity,
      Number(joiningArgs.role ?? 0),
      joiningArgs.cloud_recording_option,
      joiningArgs.cloud_recording_election,
      joiningArgs.telemetry_tracking_id
    );
    const signatureHost = generateVideoToken(
      joiningArgs.sdkKey,
      joiningArgs.sdkSecret,
      joiningArgs.topic,
      joiningArgs.sessionKey,
      joiningArgs.userIdentity,
      Number(joiningArgs.role ?? 1),
      joiningArgs.cloud_recording_option,
      joiningArgs.cloud_recording_election,
      joiningArgs.telemetry_tracking_id
    );

    joiningArgs.signature = signatureHost;

    init(joiningArgs);
  };

  useEffect(() => {
    // If Host
    if (typeParam == 'host') {
      setLoadingText('Checking Tokens...');
      const SFUserIdLocal: any = localStorage.getItem('SFUserId');
      const SFRecordIdLocal: any = localStorage.getItem('SFRecordId');

      let SFUserId: any;
      let SFRecordId: any;
      let SFUserName: any;
      let SFAccesToken: any;
      let SFRefreshToken: any;

      console.log('LOCAL', SFUserIdLocal, SFRecordIdLocal);

      // Checking params
      if (recordIdParam && userIdParam) {
        localStorage.setItem('SFUserId', userIdParam);
        localStorage.setItem('SFRecordId', recordIdParam);
        SFUserId = userIdParam;
        SFRecordId = recordIdParam;
      } else {
        SFUserId = SFUserIdLocal;
        SFRecordId = SFRecordIdLocal;
      }

      setLoadingText('Checking User in Database...');

      // Checking if user exists in DB
      getUserFromDb(SFUserId).then((res: any) => {
        console.log('userDb', res);
        // request success
        if (res?.success) {
          //user does not exist
          if (res?.data?.length === 0) {
            // redirecting code found
            if (SFCode) {
              // getting tokens by code
              getTokensByCode(SFCode)
                .then((res: any) => {
                  console.log('getTokensByCode res', res);
                  // token exists
                  if (res?.data?.access_token) {
                    SFAccesToken = res?.data?.access_token;
                    SFRefreshToken = res?.data?.refresh_token;
                    console.log('TOKENS', SFAccesToken, '-', SFRefreshToken);
                    setLoadingText('Creating New User...');
                    const myDate = new Date();
                    addHoursToDate(myDate, 2);
                    // getting user by access token
                    getUserByAccessToken(SFAccesToken)
                      .then((resSF) => {
                        console.log('getUserByAccessToken', resSF);
                        SFUserName = resSF?.data?.name;
                        const data = {
                          sfUserId: SFUserId,
                          sfUserName: SFUserName,
                          refreshToken: SFRefreshToken,
                          accessToken: SFAccesToken,
                          expirationTime: myDate
                        };
                        // saving user
                        saveUserInDb(data)
                          .then((res: any) => {
                            console.log('userDb', res);
                            setLoadingText('User created...');
                            setLoadingText('Checking if meeting exists...');
                            console.log('SFRecordIdSFRecordId', SFRecordId);
                            //getting meeting
                            getMeetingFromDb(SFRecordId)
                              .then(async (res: any) => {
                                console.log('getMeetingFromDb', res);

                                // meeting does not exist
                                if (res?.data?.length === 0) {
                                  setLoadingText('Creating new Meeting...');
                                  // getting patient
                                  getPatientInfoByRecordId(SFRecordId, SFAccesToken)
                                    .then(async (patientRes) => {
                                      console.log('patientRes', patientRes);
                                      const meetingData = {
                                        recordId: SFRecordId,
                                        meetingId: SFRecordId + Date.now(),
                                        hostId: SFUserId,
                                        patient_name: patientRes?.data?.contactName,
                                        patient_contact: patientRes?.data?.phoneNumber,
                                        status: 'Started'
                                        // startTime: new Date(),
                                        // endTime: null
                                      };
                                      // const body = {
                                      //   recordId: 'a1yVC0000008OLJYA2',
                                      //   status: 'In Progress',
                                      //   roomId: 'https://zoom.jobsitecare.com/host?userId=asq12&recordId=a1yVC0000008OLJYA2',
                                      //   roomName: 'a1yVC0000008OLJYA21716913839023'
                                      // };

                                      // Call Status Salesforce
                                      await updateCallStatus(
                                        meetingData?.meetingId,
                                        SFAccesToken,
                                        'In Progress',
                                        window.location.href
                                      );
                                      // saving meetting
                                      saveMeetingInDb(meetingData)
                                        .then((res: any) => {
                                          console.log('saveMeetingInDbbbbbbbb', res);
                                          if (res.success) {
                                            setLoadingText('Joining Session...');
                                            setTimeout(() => {
                                              // joining session
                                              joinZoomMeeting({
                                                userName: SFUserName,
                                                meeting_id: res?.data?.meeting_id,
                                                type: 'host'
                                              });
                                            }, 1000);
                                          }
                                        })
                                        .catch((err) => {
                                          console.log('saveMeetingInDb ERR', err);
                                        });
                                    })
                                    .catch((err) => console.log('getPatientInfoByRecordId err', err));
                                }
                                // meeting exists
                                else {
                                  await updateCallStatus(
                                    res?.data[0]?.meeting_id,
                                    SFAccesToken,
                                    'In Progress',
                                    window.location.href
                                  );
                                  setLoadingText('Joining Session...');
                                  setTimeout(() => {
                                    // joining session
                                    joinZoomMeeting({
                                      userName: SFUserName,
                                      meeting_id: res?.data[0]?.meeting_id,
                                      type: 'host'
                                    });
                                  }, 1000);
                                }
                              })
                              .catch((err) => {
                                console.log('getMeetingFromDb ERR', err);
                              });
                          })
                          .catch((err) => {
                            console.log(' saveUserInDb ERR', err);
                          });
                      })
                      .catch((err: any) => {
                        console.log('getUserByAccessToken err', err);
                      });
                  }
                })
                .catch((err: any) => {
                  console.log('getTokensByCode err', err);
                });
            }
            // redirecting code not found
            else {
              setLoadingText('Redirecting to Salesforce...');
              // window.location.replace(
              //   'https://test.salesforce.com/services/oauth2/authorize?response_type=token&client_id=3MVG9L6vUmMQkenPtWxRP41ZWcTlxG0hOo51xwvEXDNF8.dxs_wsQE.JcEnm2B.WFRkGVOdHsvC6eosrvhYl1&redirect_uri=https://zoom.jobsitecare.com/host'
              // );
              window.location.replace(
                'https://test.salesforce.com/services/oauth2/authorize?client_id=3MVG9L6vUmMQkenPtWxRP41ZWcTlxG0hOo51xwvEXDNF8.dxs_wsQE.JcEnm2B.WFRkGVOdHsvC6eosrvhYl1&redirect_uri=https://zoom.jobsitecare.com/host&response_type=code'
              );
            }
          }
          //user exists
          else {
            setLoadingText('User exists in Database...');

            setLoadingText('Checking Expiry time...');

            console.log('EXPIRY CONDITION BEFORE', res?.data);

            const myDate = new Date();
            addHoursToDate(myDate, 1);
            //access token is expired
            // if (new Date(res?.data[0]?.expiration_time) <= new Date(myDate)) {
            if (res?.data[0]?.isExpired) {
              console.log('EXPIRY CONDITION AFTER', res?.data[0]);
              setLoadingText('Access Token is expired. Getting new Access Token....');
              const refresh_token = res?.data[0]?.refresh_token;

              // if access token is expired then get new access token from refresh token
              getAccessTokenFromRefreshToken(refresh_token).then((res) => {
                const myDate = new Date();
                console.log('myDate BEFORE', myDate);
                addHoursToDate(myDate, 2);
                console.log('myDate AFTER', myDate);

                console.log('getAccessTokenFromRefreshToken', res);

                // getting user
                getUserByAccessToken(res.data.access_token)
                  .then((resSF) => {
                    console.log('getUserByAccessToken', resSF);
                    SFUserName = resSF?.data?.name;
                    // });
                    console.log('getAccessTokenFromRefreshToken res', res);
                    const data = {
                      sfUserId: SFUserId,
                      sfUserName: SFUserName,
                      refreshToken: refresh_token,
                      accessToken: res.data.access_token,
                      expirationTime: myDate
                    };

                    setLoadingText('Updating User in DB....');
                    // updating user data in DB
                    saveUserInDb(data)
                      .then((res: any) => {
                        console.log('userDb', res);
                        if (res.success) {
                          setLoadingText('Checking if meeting exists in DB...');
                          // getting meeting
                          getMeetingFromDb(SFRecordId)
                            .then((res: any) => {
                              console.log('getMeetingFromDb', res);

                              if (res?.data?.length === 0) {
                                //getting patient
                                getPatientInfoByRecordId(SFRecordId, data?.accessToken)
                                  .then(async (patientRes) => {
                                    setLoadingText('Creating new Meeting...');
                                    const meetingData = {
                                      recordId: SFRecordId,
                                      meetingId: SFRecordId + Date.now(),
                                      hostId: SFUserId,
                                      patient_name: patientRes?.data?.contactName,
                                      patient_contact: patientRes?.data?.phoneNumber,
                                      status: 'Started'
                                      // startTime: new Date(),
                                      // endTime: null
                                    };

                                    // Call Status Salesforce
                                    await updateCallStatus(
                                      meetingData?.meetingId,
                                      data?.accessToken,
                                      'In Progress',
                                      window.location.href
                                    );

                                    // creating new meeting in DB
                                    saveMeetingInDb(meetingData)
                                      .then((res: any) => {
                                        console.log('saveMeetingInDb', res);

                                        if (res.success) {
                                          setLoadingText('Joining the session...');

                                          joinZoomMeeting({
                                            userName: SFUserName,
                                            meeting_id: res?.data?.meeting_id,
                                            type: 'host'
                                          });
                                        }
                                      })
                                      .catch((err) => {
                                        console.log('saveMeetingInDb ERR', err);
                                      });
                                  })
                                  .catch((err) => console.log('getPatientInfoByRecordId ERR', err));
                              } else {
                                const meetingData = {
                                  status: 'Started'
                                  // startTime: new Date(),
                                  // endTime: null
                                };
                                updateMeetingInDb(SFRecordId, meetingData)
                                  .then((updateMeetingInDbRes: any) => {
                                    console.log('saveMeetingInDbbbbbbbb', res);
                                    setTimeout(() => {
                                      joinZoomMeeting({
                                        userName: SFUserName,
                                        meeting_id: res?.data[0]?.meeting_id,
                                        type: 'host'
                                      });
                                    }, 1000);
                                  })
                                  .catch((err) => {
                                    console.log('saveMeetingInDb ERR', err);
                                  });
                              }
                            })
                            .catch((err) => {
                              console.log('getMeetingFromDb ERR', err);
                            });
                        }
                      })
                      .catch((err) => {
                        console.log('saveUserInDb ERR', err);
                      });
                  })
                  .catch((err) => {
                    console.log('getAccessTokenFromRefreshToken ERR', err);
                  });
              });
            }
            // access token valid
            else {
              setLoadingText('Access Token is valid...');
              // getting user
              getUserByAccessToken(res?.data[0]?.access_token).then((resSF) => {
                console.log('getUserByAccessToken', resSF);
                SFUserName = resSF?.data?.name;
              });
              setLoadingText('Checking if meeting exists in Database...');
              //getting meeting
              getMeetingFromDb(SFRecordIdLocal)
                .then(async (getMeetingFromDbRes: any) => {
                  console.log('getMeetingFromDb', res);
                  // meeting does not exist
                  if (getMeetingFromDbRes?.data?.length === 0) {
                    setLoadingText('Creating new Meeting...');
                    //getting patient
                    getPatientInfoByRecordId(SFRecordIdLocal, res?.data[0]?.access_token)
                      .then(async (patientRes) => {
                        const meetingData = {
                          recordId: SFRecordId,
                          meetingId: SFRecordId + Date.now(),
                          hostId: SFUserId,
                          patient_name: patientRes?.data?.contactName,
                          patient_contact: patientRes?.data?.phoneNumber,
                          status: 'Started'
                          // startTime: new Date(),
                          // endTime: null
                        };
                        // Call Status Salesforce
                        await updateCallStatus(
                          meetingData?.meetingId,
                          res?.data[0]?.access_token,
                          'In Progress',
                          window.location.href
                        );

                        saveMeetingInDb(meetingData)
                          .then((res: any) => {
                            console.log('saveMeetingInDb', res);
                            if (res?.success) {
                              setLoadingText('New Meeting created...');
                              setTimeout(() => {
                                joinZoomMeeting({
                                  userName: SFUserName,
                                  meeting_id: res?.data?.meeting_id,
                                  type: 'host'
                                });
                              }, 1000);
                            }
                          })
                          .catch((err) => {
                            console.log('saveMeetingInDb ERR', err);
                          });
                      })
                      .catch((err) => console.log('getPatientInfoByRecordId err', err));
                  }
                  // meeting exist
                  else {
                    // Call Status Salesforce
                    await updateCallStatus(
                      getMeetingFromDbRes?.data[0]?.meeting_id,
                      res?.data[0]?.access_token,
                      'In Progress',
                      window.location.href
                    );
                    setLoadingText('Meeting already exists in Database...');
                    const meetingData = {
                      status: 'Started'
                      // startTime: new Date(),
                      // endTime: null
                    };
                    updateMeetingInDb(SFRecordId, meetingData)
                      .then((updateMeetingInDbRes: any) => {
                        console.log('saveMeetingInDbbbbbbbb', res);
                        setTimeout(() => {
                          joinZoomMeeting({
                            userName: SFUserName,
                            meeting_id: updateMeetingInDbRes?.data?.meeting_id,
                            type: 'host'
                          });
                        }, 1000);
                      })
                      .catch((err) => {
                        console.log('saveMeetingInDb ERR', err);
                      });
                  }
                })
                .catch((err) => {
                  console.log('getMeetingFromDb ERR', err);
                });
            }
          }
        }
        // request failed
        else {
          setLoadingText('An Error occurred on getting User from database...');
        }
      });
    }
    // If Participant
    else if (typeParam == 'join') {
      setLoadingText('Checking Info to join session');
      if (namePatient && numberPatient && meetingIdPatient) {
        setTimeout(() => {
          joinZoomMeeting({
            userName: namePatient,
            number: numberPatient,
            meeting_id: meetingIdPatient,
            type: 'patient'
          });
        }, 1000);
      } else {
        setLoadingText('Invalid Parameters');
      }
    }
  }, []);

  const init = async (joiningParams: any) => {
    await zmClient.init('en-US', `${window.location.origin}/lib`, {
      webEndpoint,
      enforceMultipleVideos: galleryViewWithoutSAB,
      enforceVirtualBackground: vbWithoutSAB,
      stayAwake: true,
      patchJsMedia: true,
      leaveOnPageUnload: true
    });
    console.log('joiningParams', joiningParams);
    try {
      setLoadingText('Joining the session...');
      await zmClient
        .join(joiningParams?.topic, joiningParams?.signature, joiningParams?.name, joiningParams?.password, 1)
        .catch((e) => {
          // message.error("Can't join session.");
          console.log('zmClient join error', e);
        });
      const stream = zmClient.getMediaStream();
      // if (joiningParams.role == 0) {
      //   if (!zmClient?.getSessionHost()) {
      //     message.error('The host will commence the meeting.');
      //     setIsHostNotFound(true);
      //     await zmClient.leave();
      //     setIsLoading(true);
      //     setLoadingText('The host will commence the meeting...');
      //   }
      // }
      setMediaStream(stream);
      // await stream?.startAudio();
      // await stream?.startVideo();
      setIsSupportGalleryView(stream.isSupportMultipleVideos());
      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);
      message.error(e.reason);
    }
  };

  useEffect(() => {
    console.log('CALLED');
    const unloadCallback = (event: any) => {
      // if (status == 'Connected') {

      if (typeParam == 'host') {
        console.log('ENDING MEETING');
        const recordIdParam: string = urlParams.get('recordId') || '';
        const SFRecordIdLocal: any = localStorage.getItem('SFRecordId');
        const SFRecordId = recordIdParam ? recordIdParam : SFRecordIdLocal;
        const SFUserIdLocal: any = localStorage.getItem('SFUserId');
        const SFUserId = userIdParam ? userIdParam : SFUserIdLocal;

        const meetingData = {
          status: 'Ended'
          // endTime: new Date()
        };
        updateMeetingInDb(SFRecordId, meetingData)
          .then((res: any) => {
            console.log('saveMeetingInDbbbbbbbb', res);
            if (res.success) {
              getUserFromDb(SFUserId)
                .then(async (getUserFromDbRes: any) => {
                  await updateCallStatus(
                    res?.data[0]?.meeting_id,
                    getUserFromDbRes?.data[0]?.access_token,
                    'Ended',
                    window.location.href
                  );
                  setLoadingText('Session left...');
                  zmClient
                    .leave(true)
                    .then(async () => {})
                    .catch((err) => {});
                  ZoomVideo.destroyClient();
                })
                .catch((err) => {
                  console.log('getUserFromDb err', err);
                });
            }
          })
          .catch((err) => {
            console.log('saveMeetingInDb ERR', err);
          });
      } else {
        console.log('LEAVING MEETING');
        zmClient
          .leave()
          .then(() => {})
          .catch((err) => {});
        // return '';
        // }
      }
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    // window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  const onConnectionChange = useCallback(
    (payload: any) => {
      console.log('payload onConnectionChange', payload);
      if (payload.state === ConnectionState.Reconnecting) {
        setIsLoading(true);
        setIsFailover(true);
        setStatus('connecting');
        const { reason, subsessionName } = payload;
        if (reason === ReconnectReason.Failover) {
          setLoadingText('Session Disconnected,Try to reconnect');
        } else if (reason === ReconnectReason.JoinSubsession || reason === ReconnectReason.MoveToSubsession) {
          setLoadingText(`Joining ${subsessionName}...`);
        } else if (reason === ReconnectReason.BackToMainSession) {
          setLoadingText('Returning to Main Session...');
        }
      } else if (payload.state === ConnectionState.Connected) {
        setStatus('connected');
        if (isFailover) {
          setIsLoading(false);
        }
        window.zmClient = zmClient;
        window.mediaStream = zmClient.getMediaStream();

        console.log('getSessionInfo', zmClient.getSessionInfo());
      } else if (payload.state === ConnectionState.Closed) {
        setStatus('closed');
        setIsLoading(true);
        dispatch({ type: 'reset-media' });
        if (payload.reason === 'ended by host') {
          Modal.warning({
            title: 'Meeting ended',
            content: 'This meeting has been ended by host'
          });
        }
        if (payload.reason === 'kicked by host') {
          setParticipantRemoved(true);
          // Modal.warning({
          //   title: 'Particpant removed',
          //   content: 'You have been removed by host'
          // });
        }
      } else if (payload.state === ConnectionState.Fail) {
        setStatus('closed');
        setIsLoading(true);
        dispatch({ type: 'reset-media' });
      } else {
        setUserLeft(true);
      }
    },
    [isFailover, zmClient]
  );
  const onMediaSDKChange = useCallback((payload: any) => {
    const { action, type, result } = payload;
    dispatch({ type: `${type}-${action}`, payload: result === 'success' });
  }, []);

  const onLeaveOrJoinSession = useCallback(async () => {
    if (status === 'closed') {
      // setIsLoading(true);
      await zmClient.join(topic, signature, name, password);
      setIsLoading(false);
    } else if (status === 'connected') {
      await zmClient.leave();
      message.warn('You have left the session.');
    }
  }, [zmClient, status, topic, signature, name, password]);

  useEffect(() => {
    try {
      zmClient.on('connection-change', onConnectionChange);
      zmClient.on('media-sdk-change', onMediaSDKChange);
      // zmClient?.on('peer-video-state-change', (payload) => {
      //   setRefresh(Date.now());
      // });
      return () => {
        zmClient.off('connection-change', onConnectionChange);
        zmClient.off('media-sdk-change', onMediaSDKChange);
      };
    } catch (error) {
      console.log('useEffect App ERR', error);
    }
  }, [zmClient, onConnectionChange, onMediaSDKChange]);

  console.log('typeParam', typeParam, typeParam != 'host' && typeParam != 'participant/');
  console.log('STATUSSSSS', status, loading);
  return (
    <div className="App">
      {(loading || isHostNotFound) && <LoadingLayer content={loadingText} />}
      {typeParam != 'host' && typeParam != 'join' ? (
        <div></div>
      ) : !loading && status != 'closed' ? (
        <ZoomMediaContext.Provider value={mediaContext}>
          <Router>
            <Switch>
              <Route
                path="/:type"
                // render={(props) => <Home {...props} status={status} onLeaveOrJoinSession={onLeaveOrJoinSession} />}
                render={(props) =>
                  participantRemoved ? (
                    <ParticipantRemoved />
                  ) : userLeft ? (
                    <UserLeft />
                  ) : (
                    <Video
                      // key={refresh}
                      props={{
                        ...props,
                        setIsLoading: setIsLoading,
                        setLoadingText: setLoadingText,
                        setRefresh: setRefresh,
                        refresh: refresh
                      }}
                    />
                  )
                }
                exact
                // component={participantRemoved ? ParticipantRemoved : userLeft ? UserLeft : Video}
              />
              {/* <Route
                path="/participant"
                render={(props) => <Home {...props} status={status} onLeaveOrJoinSession={onLeaveOrJoinSession} />}
                exact
                component={participantRemoved ? ParticipantRemoved : userLeft ? UserLeft : Video}
              /> */}
              {/* <Route path="/index.html" component={Home} exact />
              <Route path="/chat" component={Chat} />
              <Route path="/command" component={Command} />
              <Route
                path="/video"
                component={isSupportGalleryView ? (galleryViewWithAttach ? VideoAttach : Video) : VideoSingle}
              />
              <Route path="/subsession" component={Subsession} />
              <Route path="/preview" component={Preview} /> */}
            </Switch>
          </Router>
        </ZoomMediaContext.Provider>
      ) : null}
    </div>
  );
}

export default App;
