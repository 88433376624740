import React from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import { IconFont } from '../../../component/icon-font';

interface SmsButtonProps {
  onSmsClick: () => void;
}

const SmsButton = (props: SmsButtonProps) => {
  const { onSmsClick } = props;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment

    <Button
      className={classNames('screen-share-button', 'vc-button')}
      icon={<IconFont type="icon-sms" />}
      ghost={true}
      shape="circle"
      size="large"
      onClick={onSmsClick}
    />
  );
};

export { SmsButton };
