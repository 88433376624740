import React, { useCallback, useContext, useEffect, useState, useRef, useLayoutEffect, MutableRefObject } from 'react';
import { Input, Modal } from 'antd';
import ZoomContext from '../../../context/zoom-context';
import { useGalleryLayout } from '../hooks/useGalleryLayout';
import { usePagination } from '../hooks/usePagination';
import { ZoomClient, MediaStream, Participant } from '../../../index-types';
import { Dimension, Pagination } from '../video-types';
import { Avatar, Button, notification, Space } from 'antd';
import { IconFont } from '../../../component/icon-font';
import { message } from 'antd';

import './participants.scss';
import { VideoQuality } from '@zoom/videosdk';
import { SELF_VIDEO_ID } from '../video-constants';

interface ParticpantsProps {
  zmClient: ZoomClient;
  mediaStream: MediaStream | null;
  isVideoDecodeReady: boolean;
  videoRef: MutableRefObject<HTMLCanvasElement | null>;
  canvasDimension: Dimension;
  showParticipants?: boolean;
  setShowParticipants?: (showParticipants: boolean) => void;
}

const Participants = (props: ParticpantsProps) => {
  const {
    zmClient,
    mediaStream,
    isVideoDecodeReady,
    videoRef,
    canvasDimension,
    showParticipants,
    setShowParticipants
  } = props;
  const { page, pageSize, totalPage, totalSize, setPage } = usePagination(zmClient, canvasDimension);
  //   const zmClient = useContext(ZoomContext);
  const {
    visibleParticipants,
    participants,
    layout: videoLayout
  } = useGalleryLayout(zmClient, mediaStream, isVideoDecodeReady, videoRef, canvasDimension, {
    page,
    pageSize,
    totalPage,
    totalSize
  });

  const currentUser = zmClient.getCurrentUserInfo();

  const onHostAskToUnmute = useCallback((payload: any) => {
    const { reason } = payload;
    mediaStream?.unmuteAudio();
    message.info('Host unmuted you.');
    // openNotification();
  }, []);

  // console.log('PARTICIPANTS', visibleParticipants);
  // console.log('STREAM', mediaStream);

  const onMuteUnmuteAudio = useCallback((participant: Participant) => {
    const commandChannel = zmClient?.getCommandClient();
    // if (participant?.audio) {
    if (participant?.muted) {
      commandChannel.send('unmute-audio', participant?.userId);
      // openNotificationToUnmuteAudio(participant?.userId);
      // mediaStream?.unmuteAudio(participant?.userId);
    } else {
      commandChannel.send('mute-audio', participant?.userId);
      // commandChannel.send('unmute', participant?.userId);
      // mediaStream?.muteAudio(participant?.userId);
    }
    // }
  }, []);

  const onOpenCloseVideo = useCallback((participant: Participant) => {
    if (participant?.isVideoConnect) {
      const commandChannel = zmClient?.getCommandClient();
      if (participant?.bVideoOn) {
        commandChannel.send('video-off', participant?.userId);
        // message.info('Host turn you video off.');
      } else {
        commandChannel.send('video-on', participant?.userId);
        // message.info('Host turn you video on.');
      }
    }
  }, []);

  const onRemoveParticipant = useCallback((participant: Participant) => {
    zmClient.removeUser(participant?.userId);
  }, []);

  const onHostAskToToggleVideoAndAudio = useCallback((payload: any) => {
    console.log('onHostAskToToggleVideoAndAudio', payload);
    const { text } = payload;
    if (text === 'video-off') {
      mediaStream
        ?.stopVideo()
        .then(async (res) => {
          mediaStream
            ?.detachVideo(payload?.userId)
            .then(() => {})
            .catch((err) => {});
          console.log('RES', res);
          message.info('Host turn you video off.');
          // if (zmClient?.getCurrentUserInfo()?.isHost) {
          // const startVideoOptions = {
          //   hd: true,
          //   fullHd: true,
          //   ptz: mediaStream?.isBrowserSupportPTZ(),
          //   originalRatio: true
          // };
          // await mediaStream?.stopVideo();
          // await mediaStream?.startVideo(startVideoOptions);
          // }
          // zmClient?.getMediaStream()

          // mediaStream?
        })
        .catch((err) => {
          console.log('ERR', err);
        });
    } else if (text === 'video-on') {
      openNotificationToTurnOnVideo();
    } else if (text == 'mute-audio') {
      mediaStream?.muteAudio();
    } else if (text == 'unmute-audio') {
      openNotificationToUnmuteAudio();
    }
    // console.log(`Host ask to unmute the audio.`, reason);
    // mediaStream?.unmuteAudio();
    // message.info('Host unmuted you.');
    // openNotification();
  }, []);

  useEffect(() => {
    // zmClient.on('host-ask-unmute-audio', onHostAskToUnmute);
    zmClient.on('command-channel-message', onHostAskToToggleVideoAndAudio);
    return () => {
      // zmClient.off('host-ask-unmute-audio', onHostAskToUnmute);
      zmClient.off('command-channel-message', onHostAskToToggleVideoAndAudio);
    };
  }, [zmClient]);

  const openNotificationToTurnOnVideo = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          const startVideoOptions = {
            hd: true,
            fullHd: true,
            ptz: mediaStream?.isBrowserSupportPTZ(),
            originalRatio: true
          };
          mediaStream
            ?.startVideo(startVideoOptions)
            .then((res) => {
              // zmClient?.hos
              // zmClie
              // zmClient?.getMediaStream();
              console.log('RES', res);
            })
            .catch((err) => {
              console.log('ERR', err);
            });
          notification.close(key);
        }}
      >
        Confirm
      </Button>
    );
    notification.open({
      message: 'Turn On Video',
      description: 'The Host wants you to turn on your video.',
      duration: 5000,
      btn,
      key,
      onClose: () => notification.close(key)
    });
  };

  const openNotificationToUnmuteAudio = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          // mediaStream
          //   ?.startVideo()
          //   .then((res) => {
          //     zmClient?.getMediaStream();
          //     console.log('RES', res);
          //   })
          //   .catch((err) => {
          //     console.log('ERR', err);
          //   });
          mediaStream
            ?.unmuteAudio()
            .then(() => {})
            .catch(() => {});
          notification.close(key);
        }}
      >
        Confirm
      </Button>
    );
    notification.open({
      message: 'Unmute Audio',
      description: 'The Host wants to unmute your audio.',
      duration: 5000,
      btn,
      key,
      onClose: () => notification.close(key)
    });
  };

  return (
    <Modal
      open={showParticipants}
      title="Participants"
      footer={null}
      closable
      centered
      onCancel={() => setShowParticipants?.(false)}
      width={'35%'}
    >
      <div className="participants-wrap">
        {participants
          .sort((a, b) => {
            if (a.isHost) return -1;
            // If b is the host, it should come before a
            if (b.isHost) return 1;
            // Otherwise, maintain the order
            return 0;
          })
          .map((participant) => (
            <div className="participant-row">
              <div className="participant-info">
                <Avatar
                  className="participant-avatar"
                  // size="default"
                >
                  {participant?.displayName[0]}
                </Avatar>
                <div style={{ marginTop: '10px', marginLeft: '3px' }}>
                  <h4>
                    {participant?.displayName}
                    {participant?.isHost && '  (Host)'}
                  </h4>
                  <h6>{participant?.userId}</h6>
                </div>
              </div>
              {!participant?.isHost && currentUser?.isHost && (
                <div className="participant-actions">
                  <div onClick={() => onOpenCloseVideo(participant)}>
                    <IconFont
                      type={
                        participant?.isVideoConnect && participant?.bVideoOn
                          ? 'icon-video-camera-on'
                          : 'icon-video-camera-off'
                      }
                    />
                  </div>
                  <div onClick={() => onMuteUnmuteAudio(participant)}>
                    <IconFont
                      style={{ marginLeft: '8px' }}
                      type={participant?.audio && !participant?.muted ? 'icon-mic-on' : 'icon-mic-off'}
                    />
                  </div>
                  <div onClick={() => onRemoveParticipant(participant)}>
                    <IconFont style={{ marginLeft: '8px' }} type={'icon-end-call'} />
                  </div>
                </div>
              )}
            </div>
          ))}
        {/* <h2>Chat</h2> */}
        {/* <div className="chat-message-wrap"></div> */}
      </div>
    </Modal>
  );
};

export default Participants;
