import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import './participant-removed.scss';

const ParticpantRemoved = () => {
  return (
    <div className="viewport">
      <div style={{ margin: 'auto' }}>
        <h1>You have been removed by Host.</h1>
      </div>
    </div>
  );
};

export default ParticpantRemoved;
